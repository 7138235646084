import React, { Component, Fragment } from 'react';
import Auth from 'utils/auth';
import { Header } from 'components/cb';

export default class NavBar extends Component {

  constructor(props){
    super(props);
  }

  render(){
    return (
      <nav role="navigation" className="navbar navbar-custom" style={{backgroundColor:'#ff4b03'}}>
        <div className="container-fluid" style={{backgroundColor:'black', color: 'white'}}>

          <div className="navbar-header">
            <a href={Auth.loggedIn() ? '/dashboard' : '/compras' } className="navbar-brand" style={{width:'200px', color: 'white'}}>LUDUS MAGNUS</a>
          </div>

          {
            Auth.loggedIn()
              ?
              <Fragment>
                <ul className="nav navbar-nav navbar-right hidden-xs">
                  <li className="disabled"><a href="#">{Auth.getEmail()}</a></li>
                  <li className="active"><a href="/logout">Cerrar sesión</a></li>
                </ul>
                <ul className="list-inline visible-xs" style={{marginLeft:'5px', color:'white'}}>
                  <li className="disabled"><a href="#" style={{color:'white'}}>{Auth.getEmail()}</a></li>
                  <li className="active"><a href="/logout" style={{color:'white'}}>Cerrar sesión</a></li>
                </ul>
              </Fragment>
              :
              <Fragment>
                <ul className="nav navbar-nav hidden-xs">
                  <li><a href="/compras">Compras</a></li>
                  <li><a href="/suscripcion">Suscripción</a></li>
                  <li><a href="/login">Iniciar sesión</a></li>
                </ul>
                <ul className="list-inline visible-xs" style={{marginLeft:'5px', color:'white'}}>
                  <li><a href="/compras" style={{color:'white'}}>Compras</a></li>
                  <li><a href="/suscripcion" style={{color:'white'}}>Suscripción</a></li>
                  <li><a href="/login" style={{color:'white'}}>Iniciar sesión</a></li>
                </ul>
              </Fragment>

          }
        </div>
        <Header />
      </nav>
    );
  }

}

// NARANJA - #ff4b03
