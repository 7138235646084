import React, { Component, Fragment } from 'react';
import NotificationSystem from 'react-notification-system';
import { Module, Loading, Space } from 'stateless';
import { postRequest } from 'utils/api';
import { WEEK, YEAR } from 'config/constants';
import { putDecimal, format } from 'utils/parseCost';
import moment from 'moment';
import ModalCancelacion from './ModalCancelacion';
import ModalPause from './ModalPause';
import ModalReanudar from './ModalReanudar';
import { pad } from 'utils/general';

export default class Historial extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: [],
      load: true
    };
    this.notification = this.notification.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    postRequest({
      url: `s/membership`,
      body: {
        email: localStorage.email
      },
      success: ({ data }) => {
        this.setState({
          load: false, data
        });
      },
      fail: (error, status, data) => {
        console.log(error, status, data);
      }
    });
  }

  renderView() {
    const { data } = this.state;

    return (
      <div className="row">
        {
          data.map((d, i) => {
            const { _id, clave, plan, customer, subscription, fecha_subscription, fecha_cancelacion,
              fecha_pausa, fecha_reanudacion, lista, estado, movimientos, dias_gratis } = d;

            let labelPeriodo = 'MENSUAL';
            if ( plan.interval === WEEK ) {
              labelPeriodo = 'SEMANAL';
            }else if ( plan.interval === YEAR  ) {
              labelPeriodo = 'ANUAL';
            }

            const card = customer._json.payment_sources.data.find((c) => {
              return c.id === subscription.card_id;
            });

            let logo = '';
            if ( card.brand === 'visa' ) {
              logo = 'visa.png';
            }else if ( card.brand === 'american_express' ) {
              logo = 'americanexpress.png';
            }else if ( card.brand === 'mastercard' || card.brand === 'master_card' || card.brand === 'mc' ) {
              logo = 'mastercard.png';
            }

            const historial = movimientos.sort((firstOne, nextOne) => {
              if ( new Date(firstOne.fecha) > new Date(nextOne.fecha) ) {
                return -1;
              }

              if ( new Date(firstOne.fecha) < new Date(nextOne.fecha) ) {
                return 1;
              }
              return 0;
            });

            let comentario = '';
            if ( historial.length > 0 ) {
              comentario = historial[0].comentario;

              if ( comentario === 'SISTEMA DE NOTIFICACIONES DE CONEKTA' ) {
                comentario = 'SISTEMA GESTIÓN DE PAGOS';
              }
            }

            let lblDiasGratis = null;
            if ( typeof dias_gratis !== 'undefined' ) {
              if ( dias_gratis > 0 ) {
                lblDiasGratis = <p><span className="label label-primary">{dias_gratis} días gratis</span></p>;
              }
            }

            //const subscription_start = new Date(subscription.subscription_start * 1000);
            const trial_end = new Date(subscription.trial_end * 1000);

            const formalTrial = moment(trial_end).format('YYYY-MM-DD');
            const current = moment(new Date()).format('YYYY-MM-DD');

            const periodoGratis = dias_gratis > 0 && moment(formalTrial).isSameOrAfter(current);

            return (
              <div className="col-sm-12" key={i}>
                <div className="panel panel-info">
                  <div className="panel-heading">
                    <h3 className="panel-title">Folio #{pad(clave)} - {plan.name} - {labelPeriodo}</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        {
                          estado === 'CANCELADA' ?
                            <Fragment>
                              <span className="badge badge-danger">CANCELADA</span>
                              <br />
                              <strong>{moment(fecha_cancelacion).format('DD/MM/YY hh:mm A')}</strong>

                              <div className="alert alert-danger">
                                <strong>Observación:</strong> {comentario}
                              </div>
                            </Fragment>
                            : null
                        }

                        {
                          estado === 'ACTIVA' && typeof fecha_reanudacion !== 'undefined' && fecha_reanudacion !== null ?
                            <Fragment>
                              <br />
                              <span>Reanuadada el <strong>{moment(fecha_reanudacion).format('DD/MM/YY')}</strong></span>
                              <div className="alert alert-success">
                                <strong>Observación:</strong> {comentario}
                              </div>
                            </Fragment> : null
                        }

                        {
                          estado === 'PAUSA' ?
                            <Fragment>
                              <span className="badge badge-info">PAUSADA</span>
                              <br />
                              <strong>Pausada el {moment(fecha_pausa).format('DD/MM/YY hh:mm A')}</strong>
                              <div className="alert alert-info">
                                <strong>Observación:</strong> {comentario}
                              </div>
                            </Fragment>
                            : null
                        }

                        {
                          estado === 'PENDIENTE' ?
                            <Fragment>
                              <span className="badge badge-normal">Procesando cargo</span>
                            </Fragment>
                            : null
                        }

                        {
                          estado === 'DECLINADA' ?
                            <Fragment>
                              <span className="badge badge-warning">Declinada</span>
                              <br />

                              {
                                typeof historial[0].fecha !== 'undefined'
                                  ? <strong>Declinada el {moment(historial[0].fecha).format('DD/MM/YY hh:mm A')}</strong>
                                  :  null
                              }

                              <div className="alert alert-warning">
                                <strong>Observación:</strong> {comentario}
                              </div>
                            </Fragment>
                            : null
                        }
                      </div>

                      {
                        ! periodoGratis ?
                          <div className="col-sm-6">
                            {
                              estado === 'ACTIVA' ?
                                <Fragment>
                                  <Space />
                                  <ModalCancelacion id={i} itemId={_id} name={`${plan.name} - ${labelPeriodo}`}
                                    notification={this.notification}
                                    loadData={this.loadData}
                                    btnClass="btn btn-lg btn-danger" />
                                </Fragment>
                                : null
                            }

                            {
                              estado === 'ACTIVA' ?
                                <Fragment>
                                  <Space />
                                  <ModalPause id={i} itemId={_id} name={`${plan.name} - ${labelPeriodo}`}
                                    notification={this.notification}
                                    loadData={this.loadData}
                                    btnClass="btn btn-lg btn-warning" />
                                </Fragment>
                                : null
                            }

                            {
                              estado === 'PAUSA' ?
                                <Fragment>
                                  <Space />
                                  <ModalReanudar id={i} itemId={_id} name={`${plan.name} - ${labelPeriodo}`}
                                    notification={this.notification}
                                    loadData={this.loadData}
                                    btnClass="btn btn-lg btn-success" />
                                </Fragment>
                                : null
                            }
                          </div> : null
                      }
                    </div>
                  </div>

                  <ul className="list-group">
                    <li className="list-group-item">{customer._json.metadata.cliente}</li>
                    <li className="list-group-item">{customer._json.metadata.correo}</li>
                    <li className="list-group-item">{customer._json.metadata.celular}</li>
                    <li className="list-group-item"></li>
                    <li className="list-group-item">Monto por <strong>$ {putDecimal(plan.amount)} {plan.currency}</strong></li>
                    <li className="list-group-item">Creada el <strong>{moment(fecha_subscription).format('DD/MM/YY hh:mm A')}</strong></li>

                    {
                      periodoGratis ? (
                        lblDiasGratis !== null ? <li className="list-group-item">{lblDiasGratis} Su primer cargo {moment(trial_end).format('DD/MM/YY')}</li> : ''
                      ) : null
                    }

                    <li className="list-group-item">Tarjeta con terminación <strong>{card.last4}</strong> <img src={`./img/${logo}`} width="40px" alt="" className="img-thumbnail img-responsive" /></li>
                  </ul>

                  <legend>Detalle de la Suscripción</legend>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Cantidad</th>
                        <th>Programa</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        lista.map((l, i) => {
                          return (
                            <tr key={i}>
                              <td>{l.cantidad}</td>
                              <td>{l.label}</td>
                              <td>$ {format(l.total)}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>

                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  render(){
    const { load } = this.state;

    return (
      <Module title="Membresías">

        { ! load ? this.renderView() : <Loading /> }

        <NotificationSystem ref={(c) => this.notifications = c } />
      </Module>
    );
  }



  notification(params = {}){
    this.notifications.addNotification({
      title: params.title,
      message: params.message,
      level: params.type
    });
  }

}
