import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from 'stateless';
import Auth from 'utils/auth';

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: false
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    const email = this.email.value.trim();
    const pass = this.pass.value.trim();

    Auth.login(email, pass, (loggedIn) => {
      if ( ! loggedIn ){
        return this.setState({ error: true });
      }

      const { location, history } = this.props;

      if ( location.state && location.state.nextPathname ) {
        history.replace(location.state.nextPathname);
      } else {
        history.replace('/dashboard');
      }
    });
  }

  render() {
    const { error } = this.state;

    return (
      <div className="container" style={{marginBottom: '100px', marginTop: '40px'}}>
        <div className="text-center" style={{ marginTop:'10px' }}>
          <img src="./img/logo1.png" style={{ width:'200px' }} className="text-center" />
        </div>

        <form className="form-signin">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <Icon icon="user" />
              </div>
              <input type="text" ref={(e) => { this.email = e; }} className="form-control" name="email" id="email" placeholder="Email" autoComplete="off" />
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <Icon icon="lock" />
              </div>
              <input type="password" ref={(e) => { this.pass = e; }} className="form-control" name="pass" id="pass" placeholder="Password" autoComplete="off" pattern=".{8,10}" maxLength="10" />
            </div>
          </div>

          <button onClick={this.handleSubmit.bind(this)} className="btn btn-lg btn-normal btn-block" type="button">Iniciar sesion</button>

          <a href="/suscripcion" className="btn btn-lg btn-success btn-block">Suscribirse</a>

          <a href="/compras" className="btn btn-lg btn-warning btn-block">Comprar</a>

          <a href="/recuperar" className="btn btn-lg btn-danger btn-block">Recuperar contraseña</a>
        </form>

        { error &&
          (<div className="alert alert-danger" role="alert">Email / Password incorrectos, favor de verificar</div>)}

      </div>
    );
  }

}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(Login);
