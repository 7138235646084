import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Auth from 'utils/auth';
import { PrivateRoute } from 'utils/router';

import NavBar from 'components/application/home/NavBar';
import Login from 'components/application/session/Login';
import RecuperarPassword from 'components/application/session/RecuperarPassword';
import GenerarPassword from 'components/application/session/GenerarPassword';
import { Logout } from 'components/application/session/';
import ErrorBoundary from 'components/ErrorBoundary';

import { Dashboard } from 'containers/';
import { PageNotFound } from 'components/application/security';

import Historial from 'components/historial';
import ComprasSuscripciones from 'components/compras-suscripciones';
import Membresia from 'components/membresia';

import ComprarMembresia from 'components/compras-membresia';
import Inscripciones from 'components/inscripciones';

import Suscripcion from 'components/suscripcion';
import ComprasPublico from 'components/compras-publico';
import ComprobantePublico from 'components/comprobante-publico';

import TerminosCondiciones from 'components/terminos-condiciones';
import AvisoPrivacidad from 'components/aviso-privacidad';
import { Footer } from 'components/cb';

export default class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      loggedIn: Auth.loggedIn(),
      email: Auth.getEmail()
    };
  }

  updateAuth(loggedIn, email) {
    this.setState({
      loggedIn: loggedIn,
      email: email
    });
  }

  componentDidMount() {
    Auth.onChange = this.updateAuth.bind(this);
  }

  renderApp(){
    return (
      <Router>
        <div className="row row-offcanvas row-offcanvas-left">

          <div className="col-xs-12 col-sm-3 sidebar-offcanvas" role="navigation">
            <ul className="list-group panel">
              <li className="list-group-item"><a href="/dashboard"><i className="glyphicon glyphicon-home"></i><b>Inicio</b></a></li>
              <li className="list-group-item"><a href="/cargos"><i className="glyphicon glyphicon-th-list"></i>Cargos</a></li>
              <li className="list-group-item"><a href="/comprarsuscripcipn"><i className="glyphicon glyphicon-list-alt"></i>Comprar Suscripción</a></li>
              <li className="list-group-item"><a href="/membresias" ><i className="glyphicon glyphicon-lock"></i>Membresías</a></li>
              <li className="list-group-item"><a href="/comprar-inscripcion"><i className="glyphicon glyphicon-folder-open"></i>Comprar Inscripción</a></li>
              <li className="list-group-item"><a href="/inscripciones"><i className="glyphicon glyphicon-tasks"></i>Inscripciones</a></li>
            </ul>
          </div>

          <div className="col-xs-12 col-sm-9 content">

            <ul className="list-inline visible-xs">
              <li><a style={{color:'#434a54'}} href="/dashboard"><i className="glyphicon glyphicon-home"></i>  <b>Inicio</b></a></li>
              <li><a style={{color:'#434a54'}} href="/cargos"><i className="glyphicon glyphicon-th-list"></i>  Cargos</a></li>
              <li><a style={{color:'#434a54'}} href="/comprarsuscripcipn"><i className="glyphicon glyphicon-list-alt"></i>  Comprar Suscripción</a></li>
              <li><a style={{color:'#434a54'}} href="/membresias" ><i className="glyphicon glyphicon-lock"></i>  Membresías</a></li>
              <li><a style={{color:'#434a54'}} href="/comprar-inscripcion"><i className="glyphicon glyphicon-folder-open"></i> Comprar Inscripción</a></li>
              <li><a style={{color:'#434a54'}} href="/inscripciones"><i className="glyphicon glyphicon-tasks"></i> Inscripciones</a></li>
            </ul>

            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/dashboard" component={Dashboard} />

              <PrivateRoute path="/cargos" component={Historial} />
              <PrivateRoute path="/comprarsuscripcipn" component={ComprasSuscripciones} />
              <PrivateRoute path="/membresias" component={Membresia} />
              <PrivateRoute path="/comprar-inscripcion" component={ComprarMembresia} />
              <PrivateRoute path="/inscripciones" component={Inscripciones} />
              <PrivateRoute path="/terminos-condiciones" component={TerminosCondiciones} />
              <PrivateRoute path="/aviso-privacidad" component={AvisoPrivacidad} />

              <PrivateRoute path="/logout" component={Logout} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }

  renderOpen(){
    return (
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={ComprasPublico} />
          <PrivateRoute path="/login" component={Login} />
          <PrivateRoute path="/recuperar" component={RecuperarPassword} />
          <PrivateRoute path="/activar" component={GenerarPassword} />
          <PrivateRoute path="/suscripcion" component={Suscripcion} />
          <PrivateRoute path="/compras" component={ComprasPublico} />
          <PrivateRoute path="/orden" component={ComprobantePublico} />
          <PrivateRoute path="/terminos-condiciones" component={TerminosCondiciones} />
          <PrivateRoute path="/aviso-privacidad" component={AvisoPrivacidad} />

          <Route path="/logout" component={Logout} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    );
  }

  render() {
    const { loggedIn } = this.state;

    const viewMenu = loggedIn ? this.renderApp() : this.renderOpen();

    return (
      <Router>
        <ErrorBoundary>

          <NavBar {...this.state} />

          <div className="container-fluid">
            {viewMenu}
            <Footer />
          </div>

        </ErrorBoundary>
      </Router>
    );
  }

}
