import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import { Module, Loading } from 'stateless';
import List from './List';
import { postRequest } from 'utils/api';

export default class Historial extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: [],
      loading: true,
      municipio: '',
      programas: [],
      programaId: ''
    };
    this.loadData = this.loadData.bind(this);
    this.notification = this.notification.bind(this);
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    const { programaId } = this.state;

    postRequest({
      url: `s/mycharges`,
      body: {
        programaId, email: localStorage.email
      },
      success: ({ data, count, municipio, programas }) => {
        this.setState({ data, rowsTotal: count, municipio, programas, loading: false });
      },
      fail: (error, status, data) => {
        console.log(error, status, data);
      }
    });
  }

  renderList(){
    return <List {...this.state} loadData={this.loadData} notification={this.notification} />;
  }

  render(){
    const { loading } = this.state;

    const moduleList = ! loading ? this.renderList() : <Loading />;

    return (
      <Module title="Cargos">

        <div className="row">
          <div className="col-sm-12">
            {moduleList}
          </div>
        </div>

        <NotificationSystem ref={(c) => this.notifications = c } />
      </Module>
    );
  }

  notification(params = {}){
    this.notifications.addNotification({
      title: params.title,
      message: params.message,
      level: params.type
    });
  }

}
