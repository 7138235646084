import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

//import 'react-select/dist/react-select.css';
import 'styles/autocomplete.css';

const REFS = 2;

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      disabled: false,
      searchable: this.props.searchable,
      selectValue: this.props.defaultValue,
      clearable: true
    };

    this.updateValue = this.updateValue.bind(this);
  }

  componentDidUpdate(nextProps) {
    const { reset, name } = this.props;

    if ( nextProps.reset && ! reset ) {
      const context = Object.keys(this);

      this[context[REFS]][`autocomplete-${name}`].removeValue(
        this[context[REFS]][`id-autocomplete-${name}`].value
      );
    }
  }

  updateValue(newValue) {
    const nameElement = Object.keys(this)[1];
    const current = this[nameElement].value;

    const positionFind = newValue ? newValue : current;
    const { position, label } = this.findIndexValueSelect(positionFind);

    if (position !== null) {
      let dataCopy = this.state.data;
      dataCopy[position].disabled = true;

      if (current) {
        const positionCurrent = this.findIndexValueSelect(current);
        dataCopy[positionCurrent].disabled = false;
      }

      this.setState({
        selectValue: newValue,
        data: dataCopy
      });

      this.props.callback(newValue, label);
    } else {
      this.setState({ selectValue: null });

      this.props.callback(null, label);
    }
  }

  findIndexValueSelect(value) {
    let position = null;
    let label = '';
    this.state.data.filter((item, index) => {
      if (item.value === value) {
        position = index;
        label = item.label;
      }
    });
    return { position, label };
  }

  render() {
    const { name, reset } = this.props;
    const { data, clearable, disabled, selectValue, searchable } = this.state;

    const value = selectValue == null ? '' : selectValue;

    return (
      <div>
        <Select
          name={`autocomplete-${name}`}
          ref={`autocomplete-${name}`}
          options={data}
          simpleValue
          clearable={clearable}
          disabled={disabled}
          value={ reset ? '' : value }
          onChange={this.updateValue}
          searchable={searchable}
          placeholder={`Seleccione`}
          noResultsText="No se encontraron resultados"
        />

        <input
          type="hidden"
          value={value}
          id={`id-autocomplete-${name}`}
          name={`id-autocomplete-${name}`}
          ref={`id-autocomplete-${name}`}
        />
      </div>
    );
  }
}

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  reset: PropTypes.bool.isRequired,
  searchable: PropTypes.string,
  defaultValue: PropTypes.string,
  callback: PropTypes.func
};
