import axios from 'axios';
import jwt from 'jwt-simple';
import { API_KEY } from 'config/apiKey';
import { SESSION_EXPIRATION, TYPE_RANKS } from 'config/constants';
import { setupFormatDate } from 'utils/parseDates';

import 'moment/locale/es';
import moment from 'moment';

const dev = `https://ludusmagnuspagos.com.mx/api/`;
const production = `https://ludusmagnuspagos.com.mx/api/`;

export const BASE_URL = process.env.NODE_ENV === 'production' ? production : dev;
export const URL_FILES = BASE_URL.substring(0, BASE_URL.length - 4);

export const getRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .get(loadApi(params.url), {
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data))
      .catch(error => {
        console.log(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const postRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .post(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        if ( process.env.NODE_ENV !== 'production' ) {
          console.log(error);
        }
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const postRequestP = (params = {}) => {
  if ( validateParametersP(params) ) {
    axios
      .post(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.error(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const postUploadRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .post(loadApi(params.url), params.form, {
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data',
          'User': localStorage.email
        },
        onUploadProgress: params.onProgress()
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        if ( process.env.NODE_ENV !== 'production' ) {
          console.log(error);
        }
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const putRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .put(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.log(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const deleteRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .delete(loadApi(params.url),{
        headers: {
          'User': localStorage.email
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.log(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const loadApi = url => {
  const segments = url.includes('?') ? `&` : `?`;

  return `${BASE_URL}${url}${segments}key=${API_KEY}`;
};

const validateParametersP = (params) => {
  let successfully = true;

  if ( typeof(params.url) !== 'string' ) {
    successfully = false;
    throw 'url: undefined string';
  }

  if ( typeof(params.success) !== 'function' || typeof(params.fail) !== 'function' ) {
    successfully = false;
    throw 'undefined functions callback';
  }
  return successfully;
};

const validateParameters = (params) => {
  let successfully = true;

  if ( ! isParamsValidate() || ! isSessionActive() ) {
    closeSession();
    window.location.href = '/login';
    successfully = false;
  }

  if ( typeof(params.url) !== 'string' ) {
    successfully = false;
    throw 'url: undefined string';
  }

  if ( typeof(params.success) !== 'function' || typeof(params.fail) !== 'function' ) {
    successfully = false;
    throw 'undefined functions callback';
  }
  return successfully;
};

export const urlPhoto = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const pathFilesApi = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const imageExists = (imageUrl) => {
  var http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, false);
  http.send();

  if ( http.status === 404 || http.status === 401 ) {
    return false;
  }
  return true;
};

const isParamsValidate = () => {
  if ( typeof localStorage.email === 'undefined' || typeof localStorage.email !== 'string'
    || localStorage.email === null || localStorage.email === '' ) {
    return false;
  }

  if ( typeof localStorage.token === 'undefined' || typeof localStorage.token !== 'string'
    || localStorage.token === null || localStorage.token === '' ) {
    return false;
  }

  if ( typeof localStorage.type === 'undefined' || typeof localStorage.type !== 'string' || ( localStorage.type !== '1' && localStorage.type !== '0' )
    || localStorage.type === null || localStorage.type === '' ) {
    return false;
  }

  if ( typeof localStorage.access === 'undefined' || typeof localStorage.access !== 'string'
    || localStorage.access === null || localStorage.access === '' ) {
    return false;
  }

  return true;
};

const isSessionActive = () => {
  try {
    const now         = new Date();
    const limitDate   = jwt.decode(localStorage.expiration, API_KEY);
    const expiration  = limitDate.split('-');
    const TIME_ZONE   = 'YYYY-MM-DD HH:mm';
    const formatMoment = setupFormatDate(now);

    /**
     *  -------------------------------------------------
     *  | D - 0 | M - 1 | Y - 2 | H - 3 | M - 4 | S - 5 |
     *  -------------------------------------------------
     */
    const dateExpiration  = moment(`${expiration[2]}-${expiration[1]}-${expiration[0]} ${expiration[3]}:${expiration[4]}`, TIME_ZONE);
    const rightNow        = moment(`${now.getFullYear()}-${formatMoment.month}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}`, TIME_ZONE);

    const timeLeft    = dateExpiration.diff(rightNow, SESSION_EXPIRATION.RANK, true);
    const datesLeft   = dateExpiration.diff(rightNow, TYPE_RANKS.DAY);

    if ( SESSION_EXPIRATION.RANK !== TYPE_RANKS.DAY && SESSION_EXPIRATION.RANK !== TYPE_RANKS.WEEK && SESSION_EXPIRATION.RANK !== TYPE_RANKS.MONTH ) {
      if ( timeLeft > 0 && datesLeft <= 0 ) {
        return true;
      }else{
        return false;
      }
    }else{
      if ( timeLeft > 0) {
        return true;
      }else{
        return false;
      }
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

const closeSession = () => {
  delete localStorage.token;
  delete localStorage.email;
  delete localStorage.type;
  delete localStorage.access;
  delete localStorage.expiration;
};
