export const BLOQUEO = false;

export const TORTILLERIA = 'TORTILLERIA';
export const EXPENDIO = 'EXPENDIO';
export const ABARROTE = 'ABARROTE';
export const SUPER = 'SUPER';
export const ALMACEN = 'ALMACEN';
export const PRODUCION = 'PRODUCION';
export const PANADERIA = 'PANADERIA';

export const PENDIENTE = 'PENDIENTE';
export const PAGADO = 'PAGADO';

export const OXXO = 'OXXO';
export const SPEI = 'SPEI';
export const TARJETA = 'TARJETA';

export const PAN = {
  BLANCO: 'BLANCO',
  DULCE: 'DULCE',
  ESPECIAL: 'ESPECIAL',
  RELLENO: 'RELLENO'
};

export const WEEK = 'week';
export const HALF_MONTH = 'half_month';
export const MONTH = 'month';
export const YEAR = 'year';

export const CREDITO = 'CREDITO';
export const DEBITO = 'DEBITO';

export const EMPLEADOS = {
  PERSONA: 'PERSONA',
  TRABAJADOR: 'TRABAJADOR',
  RETIRADOR: 'RETIRADOR',
  CAPTURISTA: 'CAPTURISTA',
  REPARTIDORES: 'REPARTIDORES',
  PANADEROS: 'PANADEROS'
};

export const ESTADOS_REQUISIONES = {
  PENDIENTE: 'PENDIENTE',
  ASIGNADA: 'ASIGNADA',
  COMPLETA: 'COMPLETA',
  CANCELADA: 'CANCELADA'
};

export const ESTADOS_ORDENES_COMPRA = {
  PENDIENTE: 'PENDIENTE',
  ASIGNADA: 'ASIGNADA',
  COMPLETA: 'COMPLETA',
  CANCELADA: 'CANCELADA'
};

export const typesRequest = {
  list: 'LIST',
  search: 'SEARCH',
  filter: 'FILTER'
};

export const TEMPORALIDAD = {
  DIARIO: 'DIARIO',
  SEMANAL: 'SEMANAL',
  MENSUAL: 'MENSUAL',
  ANUAL: 'ANUAL',
  EVENTO: 'EVENTO'
};

export const LIMIT_CONEKTA = 2500;
export const LIMIT_OXXO = 10000;

/**
 * Plataformas permitidas
 */
const ANDROID = 'ANDROID';
const IOS     = 'IOS';
const BACKEND = 'BACKEND';
const SPECIAL = 'SPECIAL';
const URL     = 'URL';

export const platforms = {
  ANDROID: ANDROID,
  IOS: IOS,
  BACKEND: BACKEND,
  SPECIAL: SPECIAL,
  URL: URL
};

export const GEOLOCATIONS = {
  CULIACAN: {
    LATITUDE:   24.80481147653668,
    LONGITUDE:  -107.39376068115234
  }
};

/**
 * Tipo de Personas / Regimen fiscal
 */
export const FISICA = 'FISICA';
export const MORAL = 'MORAL';

/**
 * Procedencias de las empresas
 */
export const NACIONAL = 'NACIONAL';
export const EXTRANJERA = 'EXTRANJERA';

export const DIRECTO = 'DIRECTO';
export const INDIRECTO = 'INDIRECTO';

/**
 * https://momentjs.com/docs/#/manipulating/add/
 */
export const TYPE_RANKS = {
  MONTH:  'months',
  WEEK:   'weeks',
  DAY:    'days',
  HOUR:   'hours',
  MINUTE: 'minutes'
};

export const SESSION_EXPIRATION = {
  TIME: 2,
  RANK: TYPE_RANKS.HOUR
};

/**
 * Entornos
 */
export const PRODUCTION = 'PRODUCTION';
export const DEVELOPMENT = 'DEVELOPMENT';

export const TYPES_USERS = {
  SUPERADMIN:  'SUPERADMIN',
  NORMAL: 'NORMAL'
};

export const TYPES_USERS_FRONT = {
  ADMIN:  'ADMIN',
  MEMBER: 'SOCIO'
};

export const TURNS = {
  MORNING: 0,
  EVENING: 1,
  NIGHT: 2,
};

/**
 * Configuration for Chat Firebase
 */

export const CHAT = {
  name:         'ecosistemaemprendimiento',
  color:        '#607D8B',
  selectColor:  '#fff'
};

export const USER_CHAT = {
  ADMIN:    'admin',
  CUSTOMER: 'customer'
};

export const DB_FB = {
  TBL_CONVERSATIONS:  'conversations',

  CONVERSATIONS: {
    MESSAGES: 'messages'
  }
};

export const COLORS = {
  REDDISH_ORANGE: '#f65c1d',
  PALE_GREY: '#f1f1f3',
  BUTTER_SCOTCH: '#ffc636',
  GUNMETAL: '#51555e',
  GREYISH_BROWN: '#695f4e',
  TURQUOISE: '#00ada9'
};

export const PREREGISTRATION = {
  INFORMACION:    'INFORMACION',
  SMS:            'SMS',
  IDENTIFICACION: 'IDENTIFICACION',
  COMPLETO:       'TERMINOS Y CONDICIONES ACEPTADAS'
};

export const TRAVEL_MODE = {
  AIR:    'AIRE',
  EARTH:  'TIERRA'
};

export const STATE_BIYID = {
  OPEN:       'ABIERTO',
  AGREEMENT:  'TRATADO',
  PAYMENT:    'PAGADO',
  ASSIGNED:   'ASIGNADO'
};

export const STATUS_TRANSACTIONS = {
  PENDING:  'PENDIENTE',
  REJECT:   'RECHAZADA',
  CANCEL:   'CANCELADA',
  ACCEPTED: 'ACEPTADA'
};

export const MIME_TYPES = {
  PDF:  'application/pdf',
  DOC:  'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS:  'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT:  'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
};

export const VIMEO = {
  appId: '130044',
  url: 'https://player.vimeo.com'
};

// Medidas de Ingredientes

export const KILO = 'KILO';
export const LIBRA = 'LIBRA';
export const LITRO = 'LITRO';
