import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Space } from 'stateless';

export default class TitleH3 extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { icon, title, component } = this.props;

    return (
      <h3 style={{ marginTop: '20px' }}>
        <Icon icon={icon} />
        <Space />
        {title}
        <Space />
        {component}
      </h3>
    );
  }

}

TitleH3.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  component: PropTypes.any
};

TitleH3.defaultProps = {
  icon: ''
};
