import axios from 'axios';
import { BASE_URL } from 'utils/api';
import jwt from 'jwt-simple';
import * as SECRET from 'config/apiKey';
import { TYPES_USERS, SESSION_EXPIRATION } from 'config/constants';
import { setupFormatDateMoment } from 'utils/parseDates';

import 'moment/locale/es';
import moment from 'moment';

export default {
  login(email, password, cb) {
    cb = arguments[arguments.length - 1];
    if (localStorage.token) {
      if (cb) cb(true, email);
      this.onChange(true, email);
      return;
    }
    checkUser(email, password, (res) => {
      if (res.authenticated) {

        localStorage.token      = res.token;
        localStorage.email      = res.email;
        localStorage.type       = res.rol === TYPES_USERS.SUPERADMIN ? 1 : 0;
        localStorage.access     = null;
        localStorage.expiration = this.timeExpiration();

        if (cb) cb(true, email);
        this.onChange(true, email);
      } else {
        if (cb) cb(false, '');
        this.onChange(false, '');
      }
    });
  },

  timeExpiration() {
    try {
      const expiration  = moment().add(SESSION_EXPIRATION.TIME, SESSION_EXPIRATION.RANK);
      const expFormat   = setupFormatDateMoment(expiration);

      const dateExpiration = `${expFormat.date}-${expFormat.month}-${expiration.year()}-${expiration.hour()}-${expiration.minute()}-${expiration.second()}`;

      return jwt.encode(dateExpiration, SECRET.API_KEY);
    } catch (e) {
      console.log(e);
    }
  },

  getToken() {
    return localStorage.token;
  },

  getEmail(){
    return localStorage.email;
  },

  getTypeUser(){
    return localStorage.type;
  },

  isSuperAdmin() {
    return parseInt(localStorage.type) === 1;
  },

  getAccess() {
    return localStorage.access;
  },

  isParamsValidate() {
    if ( this.isSessionEmail() && this.isSessionTypeUser() && this.isSessionToken() ) {
      return true;
    }

    return false;
  },

  isSessionEmail() {
    if ( typeof localStorage.email !== 'undefined' && typeof localStorage.email === 'string'
      && localStorage.email !== null && localStorage.email !== '' ) {
      return true;
    }

    return false;
  },

  isSessionToken() {
    if ( typeof localStorage.token !== 'undefined' && typeof localStorage.token === 'string'
      && localStorage.token !== null && localStorage.token !== '' ) {
      return true;
    }

    return false;
  },

  isSessionTypeUser() {
    if ( typeof localStorage.type !== 'undefined' && typeof localStorage.type === 'string'
      && ( localStorage.type === '1' || localStorage.type === '0' )
      && localStorage.type !== null && localStorage.type !== '' ) {
      return true;
    }

    return false;
  },

  logout(cb) {
    delete localStorage.token;
    delete localStorage.email;
    delete localStorage.type;
    delete localStorage.access;
    delete localStorage.expiration;

    if (cb) cb();
    this.onChange(false, '');
  },

  loggedIn() {
    if ( this.isSessionEmail() && this.isSessionTypeUser() && this.isSessionToken() ) {
      return true;
    }
    return false;
  },

  onChange() {}
};

const checkUser = (email, pass, cb) => {
  axios({
    method: 'post',
    url: `${BASE_URL}s/login?key=${SECRET.API_KEY}`,
    data: {
      email: email,
      password: pass
    },
  }).then((response) => {
    if ( response.status === 200 ) {
      const { user } = response.data;
      cb({
        authenticated: true,
        token: Math.random().toString(36).substring(7),
        email: email,
        permissions: [],
        rol: user.rol
      });
    }
  }).catch((error) => {
    console.error('ERROR', error);
    cb({ authenticated: false, email: '' });
  });
};
