import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Login from './Login';
import Auth from 'utils/auth';

class Logout extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount() {
    Auth.logout(() => {
      window.location.href = '/login';
    });

  }

  render() {
    return (<Login {...this.props} />);
  }

}

Logout.propTypes = {
  history: PropTypes.object
};

export default withRouter(Logout);
