import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from 'stateless';
import { postRequestP } from 'utils/api';
import { isEmpty, isEmail } from 'validator';

class RecuperarPassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: false,
      email: ''
    };
    this.onChange = this.onChange.bind(this);
    this.notification = this.notification.bind(this);
  }

  onChange(event){
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleRegister(event){
    event.preventDefault();
    const { email } = this.state;

    this.setState({ isLoading: true });

    const data = {
      email
    };

    if ( this.validations(data) ) {
      postRequestP({
        url: 'recovery',
        body: data,
        success: (response, status) => {
          if ( status === 201 ) {
            this.notification({
              title: 'Confirmación',
              message: 'Solicitud recibida, si la información proporcionada es correcta, recibirás un correo electrónico para completar la recuperación.',
              type: 'success'
            });
          }else{
            this.notification({
              title: 'Aviso',
              message: response.message,
              type: 'error'
            });
          }

          this.setState({ isLoading: false });
        },
        fail: (error, status, data) => {
          this.notification({
            title: 'Aviso',
            message: data.message,
            type: 'error'
          });

          this.setState({ isLoading: false });
        }
      });
    }
  }

  validations(params){
    let validate = {
      success: true,
      message: ''
    };

    if ( isEmpty(params.email + '') ) {
      validate.success = false;
      validate.message = 'Email, es obligatorio.';
    }

    if ( validate.success && ! isEmail(params.email + '') ) {
      validate.success = false;
      validate.message = 'Email, es inválido.';
    }

    if ( ! validate.success ) {
      this.notification({ title: 'Aviso', message: validate.message, type: 'error' });
      this.setState({ isLoading: false });
    }

    return validate.success;
  }

  render() {
    const { email } = this.state;

    return (
      <div className="container" style={{marginBottom: '100px', marginTop: '40px'}}>
        <div className="text-center" style={{ marginTop:'10px' }}>
          <img src="./img/logo1.png" style={{ width:'200px' }} className="text-center" />
        </div>

        <form className="form-signin">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <Icon icon="user" />
              </div>
              <input type="text" className="form-control" name="email" id="email" placeholder="Email" autoComplete="off"
                onChange={this.onChange} defaultValue={email} />
            </div>
          </div>

          <button onClick={this.handleRegister.bind(this)} className="btn btn-lg btn-danger btn-block" type="button">Enviar</button>

        </form>

        <NotificationSystem ref={(c) => this.notifications = c } />

      </div>
    );
  }

  notification(params = {}){
    this.notifications.addNotification({
      title: params.title,
      message: params.message,
      level: params.type
    });
  }

}

RecuperarPassword.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(RecuperarPassword);
