import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class PageNotFound extends Component {

  render(){
    return (
      <div className="container">
        <div className="alert alert-danger">
          <h1>404 - Página no encontrada.</h1>
          <p>Ir al <Link to="/dashboard">Panel</Link></p>
        </div>
      </div>
    );
  }

}
