import React from 'react';
import { Route } from 'react-router-dom';

import Auth from 'utils/auth';
import { isAuthorized } from 'utils/permissions';

const requireAuth = (nextState, replace) => {
  if ( ! Auth.loggedIn() ) {
    redirect({ path: '/login', nextState, replace });
  }else if ( ! isAuthorized(nextState)  ) {
    redirect({ path: '/sin-autorizacion', nextState, replace });
  }
};

export const isLogged = (nextState, replace) => {
  if ( Auth.loggedIn() ) {
    redirect({ path: '/dashboard', nextState, replace });
  }
};

const redirect = ({ path, nextState, replace }) => {
  replace({
    pathname: path,
    state: {
      nextPathname: nextState.location.pathname
    }
  });
};

export const PrivateRoute = props => {
  return <Route {...props} onEnter={requireAuth} />;
};
