import jwt from 'jwt-simple';
import Auth from 'utils/auth';
import * as SECRET from 'config/apiKey';

export const isAuthorized = (state) => {
  let founding = false;
  const path    = state.location.pathname;

  if ( Auth.getAccess() ) {
    const access  = Auth.getAccess();
    const typeUser = Auth.getTypeUser();
    const modules  = jwt.decode(access, SECRET.API_KEY);

    if ( typeUser === 1 ) {
      founding = true;
    }else{
      modules.map((module) => {
        if ( ! founding ) {
          const dataSubModules = typeof module.subModulesID === 'undefined' ? module.submodules : module.subModulesID;

          dataSubModules.map((subModule) => {
            if ( subModule.path === path.replaceAll('/', '') ) {
              founding = true;
            }/*else if ( state.routes.length > 2 && path.match( '/' + subModule.path + '/' ) ) {
              // Es una sub-ruta, que dependende  de una ya asignadas a los permisos
              founding = true;
            }*/
          });
        }
      });
    }
  }

  return founding;
};

export const fetchModules = () => {
  let dataModules = null;

  if ( Auth.isParamsValidate() ) {
    const access      = Auth.getAccess();

    try {
      dataModules = jwt.decode(access, SECRET.API_KEY);
    } catch (e) {
      console.log(e.message);
    }
  }

  return dataModules;
};

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};
