import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Modal, Header, Title, Body } from 'react-bootstrap';
import { Icon, Space, Saving } from 'stateless';
import { Label, TextArea, DivFormGroup } from 'controls';
import { postRequest } from 'utils/api';

export default class ModalCancelacion extends Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      observacion: '',
      isLoading: false
    };
    this.close = this.close.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  close(){
    this.setState({ showModal: false, observacion: '', isLoading: false });
  }

  open(){
    this.setState({ showModal: true });
  }

  onChange(event){
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleDelete(event){
    event.preventDefault();
    this.setState({ isLoading: true });

    const { itemId, loadData, notification } = this.props;
    const { observacion } = this.state;

    postRequest({
      url: `s/cancel`,
      body: {
        id: itemId, observacion
      },
      success: (response, status) => {
        if ( status === 201 ) {
          loadData();
          this.close();
          notification({
            title: 'Confirmación existosa',
            message: `Se ha cancelado su suscripción.`,
            type: 'success'
          });
        }else{
          notification({
            title: 'Información',
            message: response.message,
            type: 'error'
          });
        }
        this.setState({ isLoading: false });
      },
      fail: (error, status, data) => {
        this.setState({ isLoading: false });
        if ( status === 401 ) {
          notification({
            title: 'Aviso',
            message: data.message,
            type: 'error'
          });
        }
        console.error(error, status, data);
      }
    });
  }

  render(){
    const { id, btnClass, name } = this.props;
    const { showModal, observacion, isLoading } = this.state;

    return (
      <span>
        <Space className="pull-right" />
        <OverlayTrigger placement="top" overlay={<Tooltip id={id}>Cancelar suscripción</Tooltip>}>
          <button onClick={this.open.bind(this)} className={btnClass} type="button">
            <Icon icon="ban-circle" /> Cancelar
          </button>
        </OverlayTrigger>

        <Modal show={showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleDelete.bind(this)}>
              <p className="lead">
                <Icon icon="warning-sign" />
                &nbsp;¿Confirma que desea Cancelar su suscripción <strong><u>{name}</u></strong>?
              </p>
              <div className="row">
                <div className="col-sm-12">
                  <DivFormGroup>
                    <Label name="observacion" title="Observación" />
                    <TextArea name="observacion" onChange={this.onChange} defaultValue={observacion} />
                  </DivFormGroup>
                </div>
              </div>
              <div className="modal-footer">
                {isLoading ? <Saving action="Cancelando" /> : ''}

                <button type="submit" disabled={isLoading} className="btn btn-danger pull-right">Aceptar</button>
                <span className="pull-right">&nbsp;</span>
                <button onClick={this.close} disabled={isLoading} className="btn btn-default pull-right" type="button">Cancelar</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </span>
    );
  }

}

ModalCancelacion.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  btnClass: PropTypes.string.isRequired,
  notification: PropTypes.func.isRequired
};
