import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Title, Body } from 'react-bootstrap';
import { Icon } from 'stateless';
import { urlPhoto } from 'utils/api';
import { format } from 'utils/parseCost';

export default class ModalDetailItem extends Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false,
    };
    this.close = this.close.bind(this);
  }

  close(){
    this.setState({ showModal: false });
  }

  open(){
    this.setState({ showModal: true });
  }

  renderDetailProducto() {
    const { showModal } = this.state;
    const { item: { nombre, descripcion, incluye, precio, imagen } } = this.props;

    const imgStyle = {
      clear: 'right',
      float: 'left',
      marginRight: '20px',
      marginBottom: '10px'
    };

    return (
      <Modal bsSize="large" animation={false} show={showModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" style={{padding:'20px'}}>
            {
              imagen.photo.length > 0 ?
                <img src={urlPhoto() + imagen.photo}
                  className="img-thumbnail img-responsive text-center" width="50%" heigth="70px" style={imgStyle} /> : null
            }
            <h2><span className="label label-success">{nombre}</span></h2>
            <br />
            <h3><span className="label label-info">$ {format(precio, 2)}</span> precio</h3>
            <br />
            <p dangerouslySetInnerHTML={{__html: descripcion}}></p>
            <h3>¿Qué incluye?</h3>
            <p dangerouslySetInnerHTML={{__html: incluye}}></p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  render(){
    return (
      <span>
        <a onClick={this.open.bind(this)} className="pull-right"><Icon icon="search" size="14pt" /></a>

        { this.renderDetailProducto() }
      </span>
    );
  }

}

ModalDetailItem.propTypes = {
  item: PropTypes.object.isRequired
};

