import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { postRequestP, urlPhoto } from 'utils/api';
import { isEmpty } from 'validator';
import { Icon, Module, Loading } from 'stateless';
import { IconBrand, TitleH3 } from 'components/cb';
import { format } from 'utils/parseCost';

class ComprobantePublico extends Component {

  constructor(props){
    super(props);
    this.state = {
      orden: null
    };
  }

  componentDidMount(){
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    if ( ! isEmpty( params.get('lm') + '') ) {
      postRequestP({
        url: `confirm`,
        body: {
          id: params.get('lm')
        },
        success: ({ data }) => {
          if ( data !== null ) {
            this.setState({
              orden: data,
              loading: false
            });
          }else{
            window.location.href = '/';
          }
        },
        fail: (error, status, data) => {
          console.log(error, status, data);
        }
      });
    }else{
      window.location.href = '/';
    }
  }

  goBack() {
    const { history } = this.props;

    setTimeout(() => {
      history.replace(`/`);
    }, 100);
  }

  renderView() {
    const { orden: { clave, amount, discount, name, last4, brand, store, detail, integrantes } } = this.state;

    return (
      <Module title="Confirmación de Compra en Línea">
        <div className="row">

          <div className="col-sm-12">
            <h3 className="text-center">PAGO PROCESADO CORRECTAMENTE <Icon icon='check' /></h3>

            <TitleH3 title="Autorización:"
              component={<strong><u>{clave}</u></strong>} />
          </div>

          <div className="col-sm-6">
            <TitleH3 icon="money-check-alt" title="Pago por"
              component={<strong>{amount}</strong>} />

            <TitleH3 icon="credit-card" title="Tarjeta "
              component={
                <Fragment>
                  <strong>{last4}</strong> <IconBrand brand={brand} />
                </Fragment>} />
          </div>
          <div className="col-sm-6">
            <TitleH3 icon="id-card" title=""
              component={<strong>{name}</strong>} />

            <TitleH3 icon="home" title="Sucursal"
              component={<strong>{store}</strong>} />
          </div>

          <div className="col-sm-12">
            <div className={`alert alert-success`} role="alert">
              <h4>Aviso</h4>
              Se envío un correo electrónico a tu bandeja de entrada con tu usuario y contraseña para acceder al <a href="https://ludusmagnuspagos.mx/login"><strong>Panel de clientes</strong></a>
            </div>
          </div>

          <div className="col-sm-12">
            <h4 style={{ marginTop: '30px' }}>Detalle de la compra</h4>
            <table className="table table-condensed table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col" width="50%">Programas</th>
                  <th scope="col" width="40%">Número de membresías</th>
                  <th scope="col" width="10%">Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  detail.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td>{d.name}</td>
                        <td>{d.quantity}</td>
                        <td>$ {format(d.amount, 2)}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
              <thead className="cart-header">
                {
                  discount > 0 ?
                    <tr>
                      <th colSpan="2" scope="col" width="90%"><span className="pull-right">Descuento</span></th>
                      <th scope="col" width="10%">$ {format(discount, 2)}</th>
                    </tr> : null
                }
                <tr>
                  <th colSpan="2" scope="col" width="90%"><span className="pull-right">Total</span></th>
                  <th scope="col" width="10%">{amount}</th>
                </tr>
              </thead>
            </table>
          </div>

          <div className="col-sm-12">
            <h4 style={{ marginTop: '30px' }}>Integrantes</h4>
            <table className="table table-condensed table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col" width="10%">Programa</th>
                  <th scope="col" width="30%">Nombre completo</th>
                  <th scope="col" width="17%">Celular</th>
                  <th scope="col" width="20%">Correo electrónico</th>
                  <th scope="col" width="10%">Sexo</th>
                </tr>
              </thead>
              <tbody>
                {
                  integrantes.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td><img src={urlPhoto() + d.programa.photo} className="img-thumbnail img-responsive text-center" width="50px" /></td>
                        <td>{d.nombre}</td>
                        <td>{d.celular}</td>
                        <td>{d.correo}</td>
                        <td>{d.sexo}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Module>
    );
  }

  render() {
    const { orden } = this.state;

    return (
      <div className="container">
        { orden !== null ? this.renderView() : <Loading /> }
      </div>
    );
  }

}

ComprobantePublico.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(ComprobantePublico);
