import { isMongoId } from 'validator';

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = 'expires='+ d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const setItem = (item) => {
  var jsonItem = JSON.stringify( item );

  document.cookie = item.id + '=' + jsonItem + ';';
};

export const setAuth = (item) => {
  var jsonItem = JSON.stringify( item );
  document.cookie = 'user=' + jsonItem + ';';
};

export const getAuth = () => {
  var name = 'user=';
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return JSON.parse(c.substring(name.length, c.length));
    }
  }
  return null;
};

export const getItem = id => {
  var name = id + '=';
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return JSON.parse(c.substring(name.length, c.length));
    }
  }
  return null;
};

export const getValue = id => {
  var name = id + '=';
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const getListCard = () => {
  const cookie = document.cookie.split(';');
  const card = [];

  for(var i = 0; i < cookie.length; i++) {
    const row = cookie[i].trim();
    const rowItem = row.split('=');

    if ( isMongoId(rowItem[0] + '') ) {
      const item = JSON.parse(rowItem[1]);

      if ( item.items > 0 ) {
        card.push(item);
      }
    }
  }

  return card;
};
