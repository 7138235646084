import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';
import { IconBrand } from 'components/cb';
import { format } from 'utils/parseCost';
import TitleH3 from './TitleH3';

export default class PayCompleted extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { payment: { clave, amount, discount, name, last4, brand, store, detail } } = this.props;

    return (
      <section className="cart-section">
        <div className="auto-container">
          <div className="sec-title-2 light centered">
            <h2 className="d-none d-sm-block">PAGO PROCESADO CORRECTAMENTE <Icon icon='check' /></h2>
            <h5 className="d-block d-sm-none">PAGO PROCESADO CORRECTAMENTE <Icon icon='check' /></h5>
          </div>

          <div className="sec-title">
            <TitleH3 title="Autorización:"
              component={<strong><u>{clave}</u></strong>} />

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <TitleH3 icon="money-check-alt" title="Pago por"
                  component={<strong>{amount}</strong>} />

                <TitleH3 icon="credit-card" title="Tarjeta "
                  component={
                    <Fragment>
                      <strong>{last4}</strong> <IconBrand brand={brand} />
                    </Fragment>} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <TitleH3 icon="id-card" title=""
                  component={<strong>{name}</strong>} />

                <TitleH3 icon="home" title="Sucursal"
                  component={<strong>{store}</strong>} />
              </div>
            </div>

            <h4 style={{ marginTop: '30px' }}>Detalle de la compra</h4>
            <table className="cart-table">
              <thead className="cart-header">
                <tr>
                  <th scope="col" width="50%">Programas</th>
                  <th scope="col" width="40%">Número de membresías</th>
                  <th scope="col" width="10%">Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  detail.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td>{d.name}</td>
                        <td>{d.quantity}</td>
                        <td>$ {format(d.amount, 2)}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
              <thead className="cart-header">
                {
                  discount > 0 ?
                    <tr>
                      <th colSpan="2" scope="col" width="90%"><span className="pull-right">Descuento</span></th>
                      <th scope="col" width="10%">$ {format(discount, 2)}</th>
                    </tr> : null
                }
                <tr>
                  <th colSpan="2" scope="col" width="90%"><span className="pull-right">Total</span></th>
                  <th scope="col" width="10%">{amount}</th>
                </tr>
              </thead>
            </table>
          </div>

        </div>
      </section>
    );
  }

}

PayCompleted.propTypes = {
  payment: PropTypes.object.isRequired
};
