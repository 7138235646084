import React, { Component } from 'react';
import { Module } from 'stateless';
import Auth from 'utils/auth';

export default class TerminosCondiciones extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const container = Auth.loggedIn() ? '' : 'container';

    return (
      <div className={container}>
        <Module title="Términos y Condiciones de Uso">
          <h3>Términos y condiciones / Acuerdo de suscripción </h3>
          <p>Bienvenido a los términos y condiciones (<strong>&quot;Términos&quot;</strong>) de ludusmagnuspagos.com.mx. Estos Términos se acuerdan entre usted y Luis Humberto Márquez Cañedo (<strong>&quot;Veintidós22&quot;</strong>, LM Barbell Club o <strong>&quot;Nosotros&quot;</strong> ) y regirán nuestros derechos y obligaciones correspondientes.</p>
          <p>Por favor considere que el uso de la plataforma https://<strong>ludusmagnuspagos.com.mx</strong> y de la membresía Veintidós22 o LM Barbell Club también se rigen por los acuerdos mencionados en la parte inferior, así como otros términos, condiciones, limitaciones y requerimientos mismos que (según sean actualizados de tiempo en tiempo) se incorporan a estos Términos. Si usted se suscribe a la membresía de algún entrenamiento en línea o presencial, usted acepta estos términos, condiciones, limitaciones y requerimientos.</p>

          <h3>Acuerdo de suscripción</h3>
          <ol style={{listStyleType: 'upper-alpha'}}>
            <li>Al suscribirte a nuestro programa Veintidós22 en línea, diste tu consentimiento para que tu suscripción comience de inmediato</li>
            <li>Tu suscripción Veintidós22 en línea se domicilia automáticamente cada mes a tu tarjeta de crédito y se cobrará el precio de la suscripción por $520 mensual hasta que dicha suscripción cumpla tres meses o sea cancelada por el CLIENTE.</li>
          </ol>

          <h3>Cancelación de la Membresía</h3>
          <ol style={{listStyleType: 'upper-alpha'}}>
            <li>La cancelación solo entrará en vigor cuando finalice tu período de facturación de 3 meses.</li>
            <li>Puedes cancelar tu suscripción tal como se describe en el plazo de suscripción (posterior a los 3 meses), al menos 5 días hábiles antes de tu próximo cargo. Contáctanos en asistencia@ludusmagnus.mx para recibir más instrucciones (agrega como asunto: “Cancelación de Suscripcion”) y tu nombre.</li>
            <li>Este programa no se puede reembolsar, congelar o traspasar por períodos usados parcialmente.</li>
          </ol>

          <h3>Beneficios de la membresía Veintidós22 y LM Barbell Club</h3>
          <ol style={{listStyleType: 'upper-alpha'}}>
            <li>Soporte en línea para atender cualquiera de tus dudas con disponibilidad 16 horas al día.</li>
            <li>Acceso a rutinas de entrenamiento por 30 días o los días correspondientes a la suscripción contratada.</li>
            <li>Acceso a un plan de entrenamiento por 30 días o los días correspondientes a la suscripción contratada.</li>
            <li>Rutinas de entrenamiento en formato de 48 horas por si se te complica entrenar en vivo con nosotros.</li>
            <li>Acceso a tips y recetas de platillos en nuestro grupo privado de Facebook.</li>
          </ol>

          <h3>Otras Limitaciones</h3>
          <ol style={{listStyleType: 'upper-alpha'}}>
            <li>Nosotros nos reservamos el derecho a aceptar o de negar la membresía en caso de incumplimiento a los presentes “Términos” de ludusmagnuspagos.com.mx, en caso de violación a derechos de terceras personas o la comisión de delitos, en relación a cualquier acción que lastime la reputación y nuestras marcas, así como la experiencia de los usuarios de Veintidós22, LM Barbell Club presencial o en línea, o en los casos en los que haya indicios de actividad fraudulenta relacionada con su cuenta.</li>
            <li>Usted no podrá transferir o ceder su membresía, ni los beneficios, incluyendo códigos promocionales o beneficios, salvo lo dispuesto por los presentes Términos.</li>
            <li>De tiempo en tiempo, Veintidós22 y LM Barbell Club podrán agregar o eliminar beneficios de la membresía. Su uso continuado de la membresía después de dichos cambios a los beneficios, será considerado como la aceptación de dichos cambios.</li>
          </ol>

          <h3>Cargos y Renovación</h3>
          <ol style={{listStyleType: 'upper-alpha'}}>
            <li>El cargo por la suscripción de la membresía Veintidós22 en línea se menciona en el apartado (B) de los Términos y condiciones de suscripción. </li>
            <li>Usted acepta el cargo mensual completo de la membresía. De tiempo en tiempo, ludusmagnuspagos.com.mx puede ofrecer diferentes términos y condiciones de la membresía y los cargos para dicha membresía pueden variar. El cargo por la membresía Veintidós22 en línea no será reembolsable salvo en cumplimiento por lo descrito en el apartado (B) en los Términos y condiciones de suscripción.</li>
            <li>En caso de que todos los métodos de pago que ludusmagnuspagos.com.mx tiene almacenados para su Cuenta sean declinados para el pago de su membresía, usted deberá proporcionar un nuevo método de pago de forma oportuna, de lo contrario su membresía será cancelada. Si usted nos proporciona un nuevo método de pago que sea elegible y, de realizarse el cobro exitosamente, su nuevo período de membresía se basará en la fecha original de renovación y no en la fecha en la que se realizó el cobro exitosamente.</li>
          </ol>

          <br />
          <p>SALVO QUE ANTES DE CUALQUIER CARGO USTED NOS NOTIFIQUE SU VOLUNTAD PARA CANCELAR O NO RENOVAR AUTOMÁTICAMENTE, USTED ENTIENDE QUE SU MEMBRESÍA VEINTIDÓS22 EN LINEA O LM BARBELL CLUB CONTINUARÁ DE FORMA AUTOMÁTICA Y NOS AUTORIZA (SIN PREVIO AVISO, SALVO QUE SEA REQUERIDO POR LA LEGISLACIÓN APLICABLE) A COBRAR LOS MONTOS APLICABLES DEL COSTO DE LA MEMBRESÍA Y DE LOS IMPUESTOS, UTILIZANDO CUALQUIER MÉTODO DE PAGO REGISTRADO EN SU CUENTA.</p>

          <h3>Membresías Promocionales de Prueba</h3>
          <p>En ocasiones, Luis Humberto Marquez Cañedo ofrece a ciertos clientes membresías promocionales o de prueba que también están sujetas a estos Términos. Los miembros de prueba pueden, en cualquier momento (a través de asistencia@ludusmagnus.mx), decidir no continuar con la membresía al final del período de prueba gratuito.</p>

          <h3>Cambios al Contrato</h3>
          <p>Luis Humberto Marquez Cañedo, puede modificar los presentes “Términos” de ludusmagnuspagos.com.mx, el Aviso de Privacidad o cualquier aspecto de la membresía Veintidós 22 o LM Barbell Club presencial o en línea, y su uso continuado de estás membresías será considerado como la aceptación de dichos cambios. Si cualquier cambio a estos Términos se determina inválido, nulo o no coercible por cualquier razón, ese cambio será independiente y no afectará la validez ni la coercibilidad de los cambios o términos restantes.</p>
          <p>LA CONTINUACIÓN DE USO DE SU MEMBRESÍA DESPUÉS DE LOS CAMBIOS A ESTOS TÉRMINOS SE CONSIDERARÁ COMO LA ACEPTACIÓN A LOS MISMOS. EN CASO DE QUE USTED NO ACEPTE LOS CAMBIOS, DEBERÁ CANCELAR SU MEMBRESÍA VEINTIDÓS22 o LM BARBELL CLUB AMBAS EN MODALIDAD PRESENCIAL O EN LÍNEA.</p>

          <h3>Terminación por parte de Veintidós22 o LM Barbell Club</h3>
          <p>Luis Humberto Marquez Cañedo se reserva el derecho a cancelar su membresía Veintidós22 o LM Barbell Club en caso de incumplimiento de los Términos después de una confirmación por escrito de la terminación. En caso de que Luis Humberto Márquez Cañedo así lo de decida, le reembolsaremos de forma prorrateada considerando los meses restantes completos de su membresía. Sin embargo, no se realizará reembolso alguno por aquellas terminaciones o cancelaciones relacionadas con conductas que violen o incumplan los presentes Términos, cualquier legislación aplicable, se consideren fraudulentas, se utilice de manera indebida la membresía Ventidós22 o LM Barbell Club, presencial o en línea, lo que contravenga los intereses de Luis Humberto Márquez Cañedo o de otro usuario.</p>

          <br />
          <br />
        </Module>
      </div>
    );
  }

}
