import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from 'stateless';
import { postRequestP } from 'utils/api';
import { isEmpty, isEmail, isHash } from 'validator';

class GenerarPassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      error: false,
      email: '',
      password: '',
      confirmacion: '',
      data: null
    };
    this.onChange = this.onChange.bind(this);
    this.notification = this.notification.bind(this);
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    if ( ! isEmpty( params.get('k') + '') && isHash( params.get('k'), 'sha512') ) {
      postRequestP({
        url: `verify`,
        body: {
          code: params.get('k')
        },
        success: (data, status) => {
          if ( status === 201 ) {
            console.log(data);
            this.setState({
              data: {},
              loading: false
            });
          }else{
            window.location.href = '/';
          }
        },
        fail: (error, status, data) => {
          console.log(error, status, data);
          window.location.href = '/';
        }
      });
    }else{
      window.location.href = '/';
    }
  }

  onChange(event){
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleRegister(event){
    event.preventDefault();
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const { email, password, confirmacion } = this.state;

    this.setState({ isLoading: true });

    const data = {
      email, password, confirmacion,
      code: params.get('k')
    };

    if ( this.validations(data) ) {
      postRequestP({
        url: 'completed',
        body: data,
        success: (response, status) => {
          if ( status === 201 ) {
            this.notification({
              title: 'Confirmación',
              message: 'Password generado correctamente, favor de iniciar sesion.',
              type: 'success'
            });

            this.rendirect();
          }else{
            this.notification({
              title: 'Aviso',
              message: response.message,
              type: 'error'
            });
          }

          this.setState({ isLoading: false });
        },
        fail: (error, status, data) => {
          this.notification({
            title: 'Aviso',
            message: data.message,
            type: 'error'
          });

          this.setState({ isLoading: false });
        }
      });
    }
  }

  rendirect() {
    const { history } = this.props;

    setTimeout(() => {
      history.replace(`/login`);
    }, 1000);
  }

  validations(params){
    let validate = {
      success: true,
      message: ''
    };

    if ( isEmpty(params.email + '') ) {
      validate.success = false;
      validate.message = 'Email, es obligatorio.';
    }

    if ( validate.success && isEmpty(params.password + '') ) {
      validate.success = false;
      validate.message = 'Password, es obligatorio.';
    }

    if ( validate.success && isEmpty(params.confirmacion + '') ) {
      validate.success = false;
      validate.message = 'Confirmación de Password, es obligatorio.';
    }

    if ( validate.success && ! isEmail(params.email + '') ) {
      validate.success = false;
      validate.message = 'Email, es inválido.';
    }

    if ( validate.success && ( params.password.length < 8 || params.password.length > 10  ) ) {
      validate.success = false;
      validate.message = 'Password, debe contener min 8 y max 10 caracteres';
    }

    if ( validate.success && ( params.confirmacion.length < 8 || params.confirmacion.length > 10  ) ) {
      validate.success = false;
      validate.message = 'Confirmación de Password, debe contener min 8 y max 10 caracteres';
    }

    if ( validate.success && ( params.password !== params.confirmacion ) ) {
      validate.success = false;
      validate.message = 'Passwords, inválidos, no coinciden.';
    }

    if ( validate.success && ! isHash( params.code, 'sha512') ) {
      validate.success = false;
      validate.message = 'Url inválido, favor de hacer el proceso desde el link que hemos enviado a tu correo.';
    }

    if ( ! validate.success ) {
      this.notification({ title: 'Aviso', message: validate.message, type: 'error' });
      this.setState({ isLoading: false });
    }

    return validate.success;
  }

  renderView() {
    const { email, password, confirmacion } = this.state;

    return (
      <div className="container" style={{marginBottom: '100px', marginTop: '40px'}}>
        <div className="text-center" style={{ marginTop:'10px' }}>
          <img src="./img/logo1.png" style={{ width:'200px' }} className="text-center" />
        </div>

        <form className="form-signin">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <Icon icon="user" />
              </div>
              <input type="text" className="form-control" name="email" id="email" placeholder="Email" autoComplete="off"
                onChange={this.onChange} defaultValue={email} />
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <Icon icon="lock" />
              </div>
              <input type="password" className="form-control" name="password" id="password" placeholder="Password"
                autoComplete="off" pattern=".{8,10}" maxLength="10"
                onChange={this.onChange} defaultValue={password} />
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <Icon icon="lock" />
              </div>
              <input type="password" className="form-control" name="confirmacion" id="confirmacion" placeholder="Confirmar Password"
                autoComplete="off" pattern=".{8,10}" maxLength="10"
                onChange={this.onChange} defaultValue={confirmacion} />
            </div>
          </div>

          <button onClick={this.handleRegister.bind(this)} className="btn btn-lg btn-success btn-block" type="button">Confirmar</button>

        </form>

        <NotificationSystem ref={(c) => this.notifications = c } />

      </div>
    );
  }

  render() {
    const { data } = this.state;

    return data !== null ? this.renderView() : null;
  }

  notification(params = {}){
    this.notifications.addNotification({
      title: params.title,
      message: params.message,
      level: params.type
    });
  }

}

GenerarPassword.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(GenerarPassword);
