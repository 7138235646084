import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Modal, Header, Title, Body } from 'react-bootstrap';
import { Icon } from 'stateless';
import { format } from 'utils/parseCost';
import { urlPhoto } from 'utils/api';

export default class DetalleCompra extends Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false
    };
    this.close = this.close.bind(this);
  }

  close(){
    this.setState({ showModal: false });
  }

  open(){
    this.setState({ showModal: true });
  }

  render(){
    const { id, lista, personas } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <OverlayTrigger placement="top" overlay={<Tooltip id={id}>Detalle</Tooltip>}>
          <button onClick={this.open.bind(this)} className="btn btn-sm btn-primary pull-right" type="button">
            <Icon icon="search" />
          </button>
        </OverlayTrigger>
        <Modal bsSize="large" show={showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Detalle de la compra</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <legend>Productos</legend>
              <table className="table table-condensed table-hover table-striped">
                <thead>
                  <tr>
                    <th width="50%">Programas</th>
                    <th width="30%">Número de membresías</th>
                    <th width="10%">Precio</th>
                    <th width="10%">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    lista.map((d, i) => {
                      return (
                        <tr key={i}>
                          <td>{d.label}</td>
                          <td>{d.cantidad}</td>
                          <td>$ {format(d.precio, 2)}</td>
                          <td>$ {format(d.total, 2)}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>

              <legend>Integrantes</legend>
              <table className="table table-condensed table-hover table-striped">
                <thead>
                  <tr>
                    <th width="5%">Programa</th>
                    <th width="40%">Nombre</th>
                    <th width="10%">Celular</th>
                    <th width="10%">Correo</th>
                    <th width="10%">Sexo</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    personas.map((p, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <img src={urlPhoto() + p.productoId.imagen.photo} className="img-thumbnail img-responsive text-center" width="50px" />
                          </td>
                          <td>{p.nombre}</td>
                          <td>{p.celular}</td>
                          <td>{p.correo}</td>
                          <td>{ p.sexo === '1' ? 'Hombre' : 'Mujer' }</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}

DetalleCompra.propTypes = {
  id: PropTypes.number.isRequired,
  ordenId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  lista: PropTypes.array.isRequired,
  personas: PropTypes.array
};
