import {
  TURNS,
  KILO, LITRO, LIBRA,
  TEMPORALIDAD
} from 'config/constants';

export const getPhotoFb = (idFB) => {
  return  `https://graph.facebook.com/${idFB}/picture?type=large`;
};

export const switchPriority = priority => {
  let text = '...';
  let color = 'default';

  if ( priority === 1 ) {
    text = 'Alta';
    color = 'danger';
  }else if ( priority === 2 ) {
    text = 'Media';
    color = 'warning';
  }else if ( priority === 3 ) {
    text = 'Normal';
    color = 'success';
  }

  return { text, color };
};

export const isObject = data => {
  return typeof data === 'object' && data !== null;
};

export const getTurn = turn => {
  if ( turn === TURNS.MORNING ) {
    return 'Matutino';
  }else if ( turn === TURNS.EVENING ) {
    return 'Vespertino';
  }else if ( turn === TURNS.NIGHT ) {
    return 'Nocturno';
  }
};

export const zeroPad = (num) => {
  // 5 caracteres
  var zero = 5 - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

export const switchMeasure = measure => {
  let label = '';
  if ( measure === KILO ) {
    label = 'Kilos';
  }else if ( measure === LITRO ) {
    label = 'Litros';
  }else if ( measure === LIBRA ) {
    label = 'Libras';
  }
  return label;
};

export const switchEstado = temporalidad => {
  if ( temporalidad === TEMPORALIDAD.DIARIO ) {
    return { text: 'Diario' };
  }else if ( temporalidad === TEMPORALIDAD.SEMANAL ) {
    return { text: 'Semanal' };
  }else if ( temporalidad === TEMPORALIDAD.MENSUAL ) {
    return { text: 'Mensual' };
  }else if ( temporalidad === TEMPORALIDAD.ANUAL ) {
    return { text: 'Anual' };
  }else if ( temporalidad === TEMPORALIDAD.EVENTO ) {
    return { text: 'Por evento' };
  }
};

export const getSucursal = sucursal => {
  let label = '';
  if ( sucursal === 1 ) {
    label = 'HQ';
  }else if ( sucursal === 2 ) {
    label = 'LM1';
  }else if ( sucursal === 3 ) {
    label = 'LM2';
  }else if ( sucursal === 4 ) {
    label = 'LM3';
  }
  return label;
};

export const pad = (string, size) => {
  const limit = typeof size !== 'undefined' ? size : 5;

  var s = String(string);
  while (s.length < (limit || 2)) {s = '0' + s;}
  return s;
};
