import React, { Component } from 'react';

export default class LabelBlock extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <h4 className="text-center" style={{color:'red'}}>SISTEMA FUERA DE SERVICIO</h4>
    );
  }

}
