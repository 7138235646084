import React, { Component } from 'react';
import { Module } from 'stateless';
import Auth from 'utils/auth';

export default class AvisoPrivacidad extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const container = Auth.loggedIn() ? '' : 'container';

    return (
      <div className={container}>
        <Module title="Aviso de Privacidad">

          <p>La protección de sus datos personales es muy importante para Luis Humberto Márquez Cañedo de Culiacán, Sinaloa, razón por la cual, este <strong>AVISO DE PRIVACIDAD</strong>, elaborado para dar cumplimiento a la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES, tiene como fin informarle el tipo de datos personales que recabamos de Usted, cómo los usamos, manejamos y aprovechamos, y con quien los compartimos.</p>

          <h3>¿Qué datos personales recabamos de Usted?</h3>
          <p>Como cliente de alguno de nuestros servicios le podemos solicitar información personal, que varía según el caso, relativa a:</p>

          <ul>
            <li>Su nombre, dirección, fecha de nacimiento.</li>
            <li>Su correo electrónico y número telefónico.</li>
            <li>Sus datos de una cuenta bancaria.</li>
            <li>Comprobantes oficiales que acrediten su identidad como su INE.</li>
          </ul>

          <h3>¿Para qué usamos sus datos personales?</h3>
          <p>Este sitio recaba y usa sus datos personales para el cumplimiento de las siguientes finalidades:</p>
          <ul>
            <li>Confirmar su identidad.</li>
            <li>Verificar la información que nos proporciona.</li>
            <li>Ofrecer el servicio que está contratando.</li>
          </ul>

          <h3>¿Con quién compartimos su información y para que fines?</h3>
          <p>Sus datos personales sólo son tratados por el personal adscrito a esta página, por lo cual, sus datos personales no se transfieren a ningún tercero ajeno a ella, salvo para el cumplimiento de obligaciones legales ante las autoridades competentes en caso de requerirse.</p>

          <h3>¿Cómo puede limitar el uso o divulgación de su información personal?</h3>
          <p>Usted puede limitar el uso y divulgación de su información personal a través de los siguientes medios que hemos instrumentado:</p>
          <ul>
            <li>Enviando correo electrónico a la siguiente dirección electrónica <a href="mailto:asistencia@ludusmagnus.mx">asistencia@ludusmagnus.mx</a></li>
            <li>Enviando un mensaje de WhatsApp al número telefónico: (667) 244 4998 y nosotros nos pondremos en contacto para atender tu petición.</li>
          </ul>

          <h3>¿Cómo conocer los cambios al presente aviso de privacidad?</h3>
          <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones por lo cual nos comprometemos a mantenerlo informado de tal situación a través de alguno de los siguientes medios:</p>
          <ul>
            <li>En la primera comunicación que tengamos con Usted después del cambio.</li>
          </ul>

          <h3>¿Cómo contactarnos?</h3>
          <p>Si Usted tiene alguna duda sobre el presente aviso de privacidad, puede dirigirse a: La dirección electrónica <a href="mailto:asistencia@ludusmagnus.mx">asistencia@ludusmagnus.mx</a></p>
          <ul>
            <li>La dirección de correo postal dirigida a la persona o departamento de privacidad, ubicada en Calle Carlos Lineo 1913, Colonia Chapultepec, Culiacán, Sinaloa, C.P. 80030</li>
            <li>Al teléfono (667) 244 4998</li>
          </ul>

          <p>Manifiesto (amos) mi (nuestro) consentimiento expreso para el tratamiento de mis (nuestros) datos personales:</p>
          <p>El cliente:</p>

          <u>______________________________________________________________________</u>

          <br />
          <br />
        </Module>
      </div>
    );
  }

}
