import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from 'stateless';
import { putDecimal } from 'utils/parseCost';
import moment from 'moment';
import { pad } from 'utils/general';

export default class List extends Component {

  constructor(props){
    super(props);
  }


  renderRows(){
    const { data } = this.props;

    return data.map((row, i) => {
      const { clave, created, suscripcionId, data: { data: { object: { billing_cycle_start, billing_cycle_end } } } } = row;
      const { customer, subscription, plan } = suscripcionId;

      const card = customer._json.payment_sources.data.find((c) => {
        return c.id === subscription.card_id;
      });

      let logo = '';
      if ( card.brand === 'visa' ) {
        logo = 'visa.png';
      }else if ( card.brand === 'american_express' ) {
        logo = 'americanexpress.png';
      }else if ( card.brand === 'mastercard' || card.brand === 'master_card' || card.brand === 'mc' ) {
        logo = 'mastercard.png';
      }else if ( card.brand === 'OxxoPay' ) {
        logo = 'oxxopay_brand.png';
      }

      const cycle_start = new Date(billing_cycle_start * 1000);
      const cycle_end = new Date(billing_cycle_end * 1000);

      return (
        <tr key={i}>
          <td>{pad(clave)}</td>
          <td>
            <img src={`./img/${logo}`} width="40px" alt="" className="img-thumbnail img-responsive" />
          </td>
          <td>
            <strong>$ {putDecimal(plan.amount)}</strong>
          </td>
          <td>{pad(suscripcionId.clave)}</td>
          <td>
            {moment(cycle_start).format('DD/MM/YY')} - {moment(cycle_end).format('DD/MM/YY')}
          </td>
          <td>
            <small><em>{moment(created).format('DD/MM/YY hh:mm A')}</em></small>
          </td>
        </tr>
      );
    });
  }

  render(){
    const { data } = this.props;
    const rows = data.length > 0 ? this.renderRows() : <RowsNotFound message="Sin resultados." colSpan={6} />;

    return (
      <table className="table table-condensed table-hover table-striped">
        <thead>
          <tr>
            <th width="5%">Folio</th>
            <th width="5%"></th>
            <th width="20%">Monto</th>
            <th width="20%">Suscripción</th>
            <th width="10%">Período</th>
            <th width="10%">Fecha</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

}

List.propTypes = {
  data: PropTypes.array.isRequired
};
