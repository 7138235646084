import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from 'stateless';
import { format } from 'utils/parseCost';
import moment from 'moment';

export default class TableFacturas extends Component {

  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { name, checked } = event.target;

    const element = name.split('-');

    this.props.onChangeFacturas({
      item: parseInt(element[1]),
      id: element[2],
      checked
    });
  }

  renderRows() {
    const { list, listChecks } = this.props;

    return list.map((r, i) => {
      const { serie, numero, importe, saldo, fecha } = r;
      const abono = importe - saldo;

      const item = listChecks.find(c => c.id === r._id);
      const elementPeriod = `factura-${i}-${item.id}`;

      return (
        <tr key={i}>
          <td>{i+1}</td>
          <td>
            <div className="col-auto my-1">
              <div className="custom-control custom-checkbox mr-sm-2">
                <input id={elementPeriod} name={elementPeriod} className="custom-control-input"
                  type="checkbox" checked={item.checked} onChange={this.onChange} />
                <label className="custom-control-label" htmlFor={elementPeriod}>
                  {serie} {numero}
                </label>
              </div>
            </div>
          </td>
          <td>{moment(fecha).format('DD/MM/YYYY')}</td>
          <td>$ {format(importe, 2)}</td>
          <td>$ {format(abono, 2)}</td>
          <td>$ {format(saldo, 2)}</td>
        </tr>
      );
    });
  }

  render() {
    const { list } = this.props;
    const rows = list.length > 0 ? this.renderRows() : <RowsNotFound message="No se encontraron resultados." colSpan={6} />;

    return (
      <div className="table-outer">
        <table className="cart-table">
          <thead className="cart-header">
            <tr>
              <th scope="col" width="5%">#</th>
              <th scope="col">Número de Factura</th>
              <th scope="col">Fecha</th>
              <th scope="col">Importe</th>
              <th scope="col">Abono en Garantía</th>
              <th scope="col">Saldo</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

}

TableFacturas.propTypes = {
  list: PropTypes.array.isRequired,
  listChecks: PropTypes.array.isRequired,
  onChangeFacturas: PropTypes.func.isRequired
};
