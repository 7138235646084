import React, { Component } from 'react';

export default class Header extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <header className="main-header" style={{display:'block'}}>

        <div className="sticky-header">
          <div className="auto-container clearfix">
            <div className="logo pull-left">
              <a href="/"><img src="/img/logo.png" alt="" title="" style={{ height:'90px' }} /></a>
            </div>
          </div>
        </div>
      </header>
    );
  }

}
