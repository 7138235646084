import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Module, Loading } from 'stateless';
import { postRequest } from 'utils/api';

export default class Admin extends Component {

  constructor(props){
    super(props);
    this.state = {
      summary: [],
      status: 0,
      loading: true
    };
  }

  componentDidMount(){
    postRequest({
      url: `s/dashboard`,
      success: ({ data }) => {
        this.setState({ summary: data, loading: false });
      },
      fail: (error, status, data) => {
        console.log(error, status, data);
      }
    });
  }

  renderSummary() {
    const { summary: { suscripciones, ultimo_cargo } } = this.state;

    return (
      <div className="row">
        <div className="col-sm-3">
          <div className="panel panel-info">
            <div className="panel-heading">
              <h3 className="panel-title">Suscripciones activas</h3>
            </div>
            <div className="panel-body text-center">
              <p className="lead">{suscripciones}</p>
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="panel panel-danger">
            <div className="panel-heading">
              <h3 className="panel-title">Último cargo</h3>
            </div>
            <div className="panel-body text-center">
              <p className="lead">{ultimo_cargo}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    const viewSummary = ! loading ? this.renderSummary(): <Loading />;

    return (
      <Module title="Inicio">
        {viewSummary}
      </Module>
    );
  }

}

Admin.propTypes = {
  props: PropTypes.node
};
