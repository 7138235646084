import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'stateless';
import Card from 'react-credit-cards';

export default class CreditCard extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { name, number, expiry, cvc, focused, issuer, paymentLoading, paymentConekta,
      handleCallback, handleInputChange, handleInputFocus, onChangeTerms, acceptTerms } = this.props;

    return (
      <div className="row clearfix" style={{marginTop: '30px'}}>
        <legend className="col-12 col-sm-12">Información de pago</legend>

        <div className="col-lg-6 col-md-6 col-sm-6 col-12">

          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={handleCallback}
            placeholders={{ name: 'NOMBRE' }}
            locale={{ valid: 'Expiración' }} />
          <br />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="form-group">
            <input type="tel" name="number" className="form-control"
              placeholder="Número de tarjeta" pattern="[\d| ]{16,22}" required
              onChange={handleInputChange} onFocus={handleInputFocus} />
          </div>
          <div className="form-group">
            <input type="text" name="name" className="form-control"
              placeholder="Nombre del tarjetahabiente" required
              onChange={handleInputChange} onFocus={handleInputFocus} />
          </div>
          <div className="row">
            <div className="col-xs-6">
              <input type="tel" name="expiry" className="form-control"
                placeholder="Fecha de expiración (MM/AA)" pattern="\d\d/\d\d" required
                onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
            <div className="col-xs-6">
              <input type="tel" name="cvc" className="form-control"
                placeholder="CVC" pattern="\d{3,4}" required
                onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-6">
              <div className="custom-control custom-checkbox mr-sm-2">
                <input id="accept-terms" name="accept-terms" className="custom-control-input"
                  type="checkbox" onChange={onChangeTerms} defaultChecked={acceptTerms} />
                <label className="custom-control-label" htmlFor="accept-terms"><Space /><Space />Acepto</label>
                <Space />
                <Space />
                <a href="/terminos-condiciones" target="_blank" rel="noreferrer">términos y condiciones</a>
              </div>
            </div>
          </div>
          <input type="hidden" name="issuer" value={issuer} />
          <br />
          <div className="form-actions text-center">
            {
              paymentLoading && paymentConekta ?
                <div className="progress" style={{ marginTop:'30px', marginBottom:'30px' }}>
                  <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width:'100%', backgroundColor:'#5cb85c' }}>
                    <span>Tu pago esta procesandose, favor de esperar</span>
                  </div>
                </div>
                : null
            }
          </div>
        </div>
      </div>
    );
  }

}

CreditCard.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  expiry: PropTypes.string.isRequired,
  cvc: PropTypes.string.isRequired,
  focused: PropTypes.string.isRequired,
  issuer: PropTypes.string.isRequired,
  paymentLoading: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.bool.isRequired,
  paymentConekta: PropTypes.bool.isRequired,
  handleCallback: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputFocus: PropTypes.func.isRequired,
  onChangeTerms: PropTypes.func.isRequired,
};

