import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound, Icon, Space } from 'stateless';
import moment from 'moment';
import { format } from 'utils/parseCost';
import { pathFilesApi } from 'utils/api';
import { OXXO } from 'config/constants';
import DetalleCompra from './DetalleCompra';

export default class List extends Component {

  constructor(props){
    super(props);
  }

  renderDiscount(row) {
    const { descuento, cuponId } = row;

    if ( descuento > 0 && cuponId !== null ) {
      return (
        <div>
          $ {format(descuento, 2)} <small>({cuponId.descuento}% descuento)</small>
        </div>
      );
    }
    return null;
  }

  renderRows(){
    const { data } = this.props;

    return data.map((row, i) => {
      const { _id, reference, auth_code, nombre, celular, correo, sucursalId, total, created,
        order: { charges: { data } }, lista, personas, status, source, pdf } = row;
      const { payment_method: { brand, service_name } } = data[0];

      const path = `${pathFilesApi()}${pdf}`;

      let logo = '';
      if ( brand === 'visa' ) {
        logo = 'visa.png';
      }else if ( brand === 'american_express' ) {
        logo = 'americanexpress.png';
      }else if ( brand === 'mastercard' || brand === 'master_card' || brand === 'mc' ) {
        logo = 'mastercard.png';
      }else if ( service_name === 'OxxoPay' ) {
        logo = 'oxxopay_brand.png';
      }

      return (
        <tr key={i}>
          <td>
            <img src={`./img/${logo}`} width="40px" alt="" className="img-thumbnail img-responsive" />

            {
              source === OXXO ?
                <Fragment>
                  <br />
                  <strong>{reference}</strong>
                </Fragment> : null
            }
          </td>
          <td>
            {auth_code}
          </td>
          <td>
            {this.renderDiscount(row)}
            <strong>$ {format(total, 2)}</strong>
          </td>
          <td>
            <div className="row">
              <div className="col-sm-10">
                <strong>{nombre}</strong>
                <br />
                <small>{celular}</small>
                <br />
                <small>{correo}</small>
              </div>
              <div className="col-sm-2">
              </div>
            </div>
          </td>
          <td>
            {
              personas.map((p, i) => {
                return <small key={i}><u>{p.productoId.nombre},</u></small>;
              })
            }
          </td>
          <td>
            <DetalleCompra id={i} lista={lista} personas={personas} ordenId={_id} status={status} {...this.props} />
            <Space className="pull-right" />
            {
              source === OXXO ?
                <a href={path} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-default pull-right"><Icon icon="barcode" /></a> : null
            }
          </td>
          <td>{sucursalId.nombre} - {sucursalId.etiqueta}</td>
          <td>
            <small><em>{moment(created).format('DD/MM/YY hh:mm A')}</em></small>
          </td>
          <td></td>
        </tr>
      );
    });
  }

  render(){
    const { data } = this.props;
    const rows = data.length > 0 ? this.renderRows() : <RowsNotFound message="Sin resultados." colSpan={8} />;

    return (
      <table className="table table-condensed table-hover table-striped">
        <thead>
          <tr>
            <th width="5%"></th>
            <th width="10%">Autorización</th>
            <th width="20%">Monto</th>
            <th width="25%">Nombre</th>
            <th width="10%"></th>
            <th width="10%"></th>
            <th width="10%">Sucursal</th>
            <th width="10%">Fecha</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

}

List.propTypes = {
  data: PropTypes.array.isRequired
};
