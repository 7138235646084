import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { Module, Loading, Space, Icon } from 'stateless';
import { Input, InputEmail, DivFormGroup } from 'controls';
import { postRequestP, urlPhoto } from 'utils/api';
import { LIMIT_CONEKTA, BLOQUEO, CREDITO } from 'config/constants';
import { PUBLIC } from 'config/conekta';
import Credit_Card from './Credit_Card';
import { format } from 'utils/parseCost';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'utils/payment';
import 'react-credit-cards/es/styles-compiled.css';
import { isEmpty, isEmail, isMobilePhone, isMongoId } from 'validator';
import Auth from 'utils/auth';
import { ModalDetailItem, LabelBlock } from 'components/cb';

class Suscripcion extends Component {

  constructor(props){
    super(props);
    this.state = {
      nombre: '',
      celular: '',
      correo: '',
      sexo: '1',
      calle: '',
      numero: '',
      colonia: '',
      codigo_postal: '',
      tipo_tarjeta: '',
      sucursalId: '',
      programa: '',
      cupon: '',
      porcentaje: 0,
      labelDescuento: 'Descuento',
      descuento: 0,
      total: 0,
      totalPagar: 0,
      total22: 0,
      totalKeto: 0,
      totalKetoPRO: 0,
      totalFITT: 0,
      totalFuncional: 0,
      number: '',
      name: '',
      expiry: '',
      cvc: '',
      issuer: '',
      acceptTerms: false,
      focused: '',
      error: {
        visible: false,
        type: '',
        title: '',
        message: ''
      },
      listChecks: [],
      paymentLoading: false,
      paymentCompleted: false,
      paymentOxxo: false,
      paymentConekta: false,
      stores: [],
      products: [],
      cart: [],
      totalItemsCart: 0,
      load: true,
      bloqueo: BLOQUEO
    };
    this.onChange = this.onChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount(){
    this.loadData();
  }

  loadData() {
    postRequestP({
      url: 's/catalogs',
      body: {},
      success: ({ products, stores }, status) => {
        if ( status === 200 ) {
          let total = 0;
          const cart = [];

          products.map(d => {
            let item = {
              id: d._id,
              items: 0,
              price: d.precio
            };

            cart.push(item);
          });

          this.setState({
            products, stores, cart, total,
            load: false
          });
        }else{
          this.setState({ load: true });
        }
      },
      fail: (error, status, data) => {
        console.log('fail', error, status, data);
      }
    });
  }

  increment(product, items) {
    items++;

    if ( items === 1 ) {
      this.updateShopingCart({
        id: product._id,
        items,
        price: product.precio
      });
    }
  }

  decrement(product, items) {
    if ( items === 1 ) {
      if ( items > 0 ) {
        items--;
      }

      this.updateShopingCart({
        id: product._id,
        items,
        price: product.precio
      });
    }
  }

  updateShopingCart({ id, items }) {
    const { cart, descuento } = this.state;
    let totalItemsCart = 0;
    let total = 0;

    cart.map(c => {
      if ( c.id === id ) {
        c.items = items;
      }

      totalItemsCart += c.items;

      total += (c.items * c.price);
    });

    const totalPagar = total - descuento;

    this.setState({ cart, totalItemsCart, total, totalPagar });
  }

  isPersonaCompleted() {
    const { nombre, celular, correo } = this.state;

    if ( ! isEmpty(nombre) && ! isEmpty(celular) && ! isEmpty(correo) && isEmail(correo)  ) {
      return true;
    }

    return false;
  }

  onChange(event) {
    const { name, value, type, checked } = event.target;

    if ( type === 'checkbox' ) {
      if ( name === 'accept-terms' ) {
        this.setState({ acceptTerms: checked });
      }else{
        const item = name.split('-');
        const row = item[1]+'-'+item[2];

        if ( checked ) {
          if ( this.isPersonaCompleted() ) {
            const { nombre, celular, correo, sexo } = this.state;

            this.frm[`nombre-${row}`].value = nombre;
            this.frm[`nombre-${row}`].disabled = true;

            this.frm[`celular-${row}`].value = celular;
            this.frm[`celular-${row}`].disabled = true;

            this.frm[`correo-${row}`].value = correo;
            this.frm[`correo-${row}`].disabled = true;

            if ( sexo === '1' ) {
              this.frm[`rdo1-${row}`].checked = true;
            }else if ( sexo === '2' ) {
              this.frm[`rdo2-${row}`].checked = true;
            }

            this.setState({ [name]: value });
          }else{
            this.setState({
              error: {
                visible: true,
                type: 'warning',
                title: 'Información incompleta',
                message: 'Debe completar la Información'
              }
            });
          }
        }else{
          this.frm[`nombre-${row}`].value = '';
          this.frm[`nombre-${row}`].disabled = false;

          this.frm[`celular-${row}`].value = '';
          this.frm[`celular-${row}`].disabled = false;

          this.frm[`correo-${row}`].value = '';
          this.frm[`correo-${row}`].disabled = false;

          this.frm[`rdo1-${row}`].checked = false;
          this.frm[`rdo2-${row}`].checked = false;

          this.setState({ [name]: value });
        }
      }
    }else{
      this.setState({ [name]: value.trim() });
    }
  }

  renderView() {
    const { total, totalPagar, error: { visible, type, title, message },
      nombre, celular, correo, sexo, calle, numero, colonia, codigo_postal, sucursalId,
      stores, products, cart, totalItemsCart, paymentLoading, paymentOxxo, bloqueo } = this.state;

    const integrantes = [];
    let item = 1;

    let styleBloqueo = {};
    if ( bloqueo ) {
      styleBloqueo = {
        background: 'transparent',
        border: 'transparent',
        cursor: 'default'
      };
    }

    products.map((p) => {
      const current = cart.find(c => c.id.toString() === p._id.toString());

      if ( typeof current !== 'undefined' && current.items > 0 ) {
        for (let i = 0; i < current.items; i++) {
          const elm = `${p._id}-${i}`;

          const row = (
            <div key={elm} className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="panel panel-success">
                <div className="panel-heading">
                  <h3 className="panel-title">Integrante #{item}</h3>
                </div>
                <div className="panel-body">
                  <div className="image-box text-center">
                    {
                      p.imagen.photo.length > 0 ?
                        <img src={urlPhoto() + p.imagen.photo} className="img-thumbnail img-responsive text-center" width="150px" heigth="100px" /> : null
                    }

                    <div className="col-auto my-1">
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <input id={`datos-${elm}`} name={`datos-${elm}`} ref={`datos-${elm}`} className="custom-control-input"
                          type="checkbox" onChange={this.onChange} />
                        <label className="custom-control-label" htmlFor={`datos-${elm}`}><Space />Soy la misma persona</label>
                      </div>
                    </div>

                    <DivFormGroup>
                      <input type="text" id={`nombre-${elm}`} name={`nombre-${elm}`} ref={`nombre-${elm}`} className="form-control" required
                        maxLength={200} placeholder="Nombre completo" />
                    </DivFormGroup>

                    <DivFormGroup>
                      <input type="tel" id={`celular-${elm}`} name={`celular-${elm}`} ref={`celular-${elm}`} className="form-control"
                        pattern="[0-9]{10}" required maxLength={10} placeholder="Celular, ejem 6677112233" />
                    </DivFormGroup>

                    <DivFormGroup>
                      <input type="email" id={`correo-${elm}`} name={`correo-${elm}`} ref={`correo-${elm}`} className="form-control" required
                        maxLength={200} placeholder="Correo electrónico" />
                    </DivFormGroup>

                    <DivFormGroup>
                      <label className="radio-inline">
                        <input type="radio" name={`sexo-${elm}`} value={1} id={`rdo1-${elm}`} /> <strong>Masculino</strong>
                      </label>
                      <Space /><Space /><Space />
                      <label className="radio-inline">
                        <input type="radio" name={`sexo-${elm}`} value={2} id={`rdo2-${elm}`} /> <strong>Femenino</strong>
                      </label>
                    </DivFormGroup>
                  </div>
                </div>
              </div>
            </div>
          );

          integrantes.push(row);
          item++;
        }
      }
    });

    return (
      <Module title="Registro">
        <h3 className="text-center">REGISTRO DE SUSCRIPCIÓN MENSUAL</h3>
        <br />

        <form ref={(form) => { this.frm = form; }} onSubmit={this.handlePayment.bind(this)}>

          <div className="row">
            <div className="col-sm-6">
              <DivFormGroup>
                <Input name="nombre" placeholder="Nombre completo" required={true}
                  defaultValue={nombre} onChange={this.onChange} maxLength={200} />
              </DivFormGroup>

              <DivFormGroup>
                <input type="tel" id="celular" name="celular"
                  pattern="[0-9]{10}" required
                  placeholder="Celular, ejem 6677112233" className="form-control"
                  defaultValue={celular} onChange={this.onChange} maxLength={10} />
              </DivFormGroup>

              <DivFormGroup>
                <InputEmail name="correo" placeholder="Correo electrónico" required={true}
                  defaultValue={correo} onChange={this.onChange} maxLength={200} />
              </DivFormGroup>

              <div className="form-group inline">
                <label>Sexo</label>
                <DivFormGroup>
                  <label className="radio-inline">
                    <input type="radio" name="sexo" id="rdo-1"
                      onChange={this.onChange} value={'1'} defaultChecked={sexo === '1'} /> <strong>Masculino</strong>
                  </label>
                  <Space /><Space /><Space />
                  <label className="radio-inline">
                    <input type="radio" name="sexo" id="rdo-2"
                      onChange={this.onChange} value={'2'} defaultChecked={sexo === '2'} /> <strong>Femenino</strong>
                  </label>
                </DivFormGroup>

                <DivFormGroup>
                  <select name="sucursalId" id="sucursalId" className="form-control"
                    defaultValue={sucursalId} onChange={this.onChange} required={true}>
                    <option value="">Sucursal</option>
                    {
                      stores.map((s, i) => <option key={i} value={s._id}>{s.etiqueta}</option>)
                    }
                  </select>
                </DivFormGroup>
              </div>
            </div>

            <div className="col-sm-6">
              <DivFormGroup>
                <Input name="calle" placeholder="Calle" required={true}
                  defaultValue={calle} onChange={this.onChange} maxLength={255} />
              </DivFormGroup>
              <DivFormGroup>
                <Input name="numero" placeholder="Número"
                  defaultValue={numero} onChange={this.onChange} maxLength={20} />
              </DivFormGroup>
              <DivFormGroup>
                <Input name="colonia" placeholder="Colonia"
                  defaultValue={colonia} onChange={this.onChange} maxLength={100} />
              </DivFormGroup>
              <DivFormGroup>
                <Input name="codigo_postal" placeholder="Código postal"
                  defaultValue={codigo_postal} onChange={this.onChange} maxLength={5} />
              </DivFormGroup>
            </div>
          </div>

          <div className="row clearfix" style={{marginTop: '30px'}}>
            <legend className="col-6 col-sm-6">Programas</legend>

            <legend className="col-6 col-sm-6">
              <span className="pull-right"><Icon icon="shopping-cart" /> <strong>{totalItemsCart}</strong></span>
            </legend>

            <div className="col-sm-12">
              <div className="pricing">
                <ul>
                  {
                    products.map((p, i) => {
                      const current = cart.find(c => c.id.toString() === p._id.toString());
                      const items = typeof current !== 'undefined' ? current.items : 0;

                      let lblDiasGratis = <p><span><Space /></span></p>;
                      if ( typeof p.dias_gratis !== 'undefined' ) {
                        if ( p.dias_gratis > 0 ) {
                          lblDiasGratis = <p><span className="label label-info">{p.dias_gratis} días gratis</span></p>;
                        }
                      }

                      return (
                        <li key={i} className="col-xs-12 col-sm-4 price-primary" style={{backgroundColor:'white'}}>
                          <div className="price-title" style={{backgroundColor:'#ff4b03'}}>
                            <h3>$ {format(p.precio, 2)}</h3>
                          </div>
                          <div className="price-body" style={{backgroundColor:'white'}}>
                            <h4 style={{height:'50px'}}>{p.nombre}</h4>

                            <ModalDetailItem item={p} />

                            {lblDiasGratis}

                            <div className="text-center">
                              {
                                p.imagen.photo.length > 0 ?
                                  <img src={urlPhoto() + p.imagen.photo} className="img-thumbnail text-center" style={{width:'100px', height:'100px'}} /> : null
                              }
                            </div>
                          </div>
                          <div className="price-foot text-center" style={{backgroundColor:'black'}}>
                            <div className="btn-group">
                              <button type="button" onClick={this.decrement.bind(this, p, items)} className="btn btn-danger"><Icon icon="minus" /></button>
                              <button type="button" className="btn btn-danger">{items}</button>
                              <button type="button"  onClick={this.increment.bind(this, p, items)} className="btn btn-danger"><Icon icon="plus" /></button>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            </div>
          </div>

          {
            totalItemsCart > 0 && totalPagar > 0 ?
              <Fragment>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <table className="table table-condensed table-hover table-striped">
                      <thead>
                        <tr>
                          <th scope="col" width="25%">Programas</th>
                          <th scope="col" width="25%">Cantidad</th>
                          <th scope="col" width="25%">Precio</th>
                          <th scope="col" width="25%">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          products.map((p, i) => {
                            const current = cart.find(c => c.id.toString() === p._id.toString());

                            if ( current.items > 0 ) {
                              const totalProducto = current.items * p.precio;

                              return (
                                <tr key={i}>
                                  <td>
                                    {
                                      p.imagen.photo.length > 0 ?
                                        <img src={urlPhoto() + p.imagen.photo} className="img-thumbnail img-responsive text-center" width="50px" /> : null
                                    }
                                    <Space />
                                    <Space />
                                    <Space />
                                  </td>
                                  <td>{current.items}</td>
                                  <td>$ {format(p.precio, 2)}</td>
                                  <td>$ {format(totalProducto, 2)}</td>
                                </tr>
                              );
                            }
                          })
                        }
                      </tbody>
                      <thead className="cart-header">
                        <tr>
                          <th colSpan="3" scope="col" width="90%"><span className="pull-right">Total</span></th>
                          <th scope="col" width="10%">$ {format(total, 2)}</th>
                        </tr>
                        <tr>
                          <th colSpan="3" scope="col" width="90%"><span className="pull-right">Total a pagar</span></th>
                          <th scope="col" width="10%">$ {format(totalPagar, 2)}</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </Fragment> : null
          }

          {
            totalItemsCart > 0 && totalPagar > 0 ?
              <div className="row clearfix" style={{marginTop: '30px'}}>
                <legend className="col-12 col-sm-12">Integrantes</legend>
                <br />
                <br />
                {integrantes}
              </div> : null
          }

          {
            totalItemsCart > 0 && totalPagar > 0 && totalPagar <= LIMIT_CONEKTA ?
              <Credit_Card {...this.state}
                onChange={this.onChange.bind(this)}
                handleCallback={this.handleCallback}
                handleInputChange={this.handleInputChange}
                handleInputFocus={this.handleInputFocus}
                onChangeTerms={this.onChange} /> : null
          }

          {
            ! paymentLoading ?
              <div className="text-center" style={{marginTop: '10px'}}>
                {
                  totalItemsCart > 0 && totalPagar > 0 && totalPagar < LIMIT_CONEKTA
                    ? <button
                      onClick={this.handlePayment.bind(this)}
                      ref={btn => { this.btnT = btn; }}
                      type="button" className="btn btn-lg btn-success" style={styleBloqueo} ><Icon icon="credit-card" /> Suscribirme</button> : null
                }
              </div>
              : null
          }

          { bloqueo ? <LabelBlock /> : null }

          {
            paymentLoading && paymentOxxo ?
              <div className="progress" style={{ marginTop:'30px', marginBottom:'30px' }}>
                <div className="progress-bar progress-bar-warning progress-bar-striped active" role="progressbar"
                  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width:'100%', backgroundColor:'#ffc107' }}>
                  <span>Generando orden para pago en Oxxo, favor de esperar</span>
                </div>
              </div>
              : null
          }

          <br />

          {
            visible ?
              <div className={`alert alert-${type}`} role="alert">
                <h4>{title}</h4>
                {message}
              </div> : null
          }
        </form>
      </Module>
    );
  }

  render(){
    const { load } = this.state;

    return (
      <div className="container">
        { ! load ? this.renderView() : <Loading /> }

        <NotificationSystem ref={(c) => this.notifications = c } />
      </div>
    );
  }

  handlePayment(event) {
    event.preventDefault();

    this.btnT.setAttribute('disabled', 'disabled');

    if ( this.validations() ) {
      this.getTokenCard((isValid, tokenId) => {
        if ( isValid ) {
          this.cleanError();

          this.setState({ paymentLoading: true, paymentConekta: true, paymentOxxo: false });

          const { total, totalPagar, descuento, name, nombre, celular, correo, sucursalId,
            calle, numero, colonia, codigo_postal, sexo, cupon, cart, products, tipo_tarjeta } = this.state;

          const items = [];

          cart.map(c => {
            if ( c.items > 0 ) {
              const current = products.find(p => p._id.toString() === c.id.toString());

              items.push({
                name: current.nombre,
                productoId: c.id,
                count: c.items,
                price: c.price,
                dias_gratis: current.dias_gratis
              });
            }
          });

          const integrantes = [];

          products.map((p) => {
            const current = cart.find(c => c.id.toString() === p._id.toString());

            if ( current.items > 0 ) {
              for (let i = 0; i < current.items; i++) {
                const elm = `${p._id}-${i}`;

                const sexo = this.frm[`rdo1-${elm}`].checked ? '1' : '2';

                integrantes.push({
                  productoId: current.id,
                  nombre: this.frm[`nombre-${elm}`].value,
                  celular: this.frm[`celular-${elm}`].value,
                  correo: this.frm[`correo-${elm}`].value,
                  sexo
                });
              }
            }
          });

          postRequestP({
            url: 's/subscription',
            body: {
              tokenId, name, nombre, celular, correo, sexo, sucursalId,
              items, calle, numero, colonia, codigo_postal,
              total, totalPagar, descuento, cupon, integrantes,
              tipo_tarjeta
            },
            success: ({ user }, status) => {
              if ( status === 201 ) {
                console.log('user: ', user);

                localStorage.token      = Math.random().toString(36).substring(7);
                localStorage.email      = user.email;
                localStorage.type       = 0;
                localStorage.access     = null;
                localStorage.expiration = Auth.timeExpiration();

                window.location.href = '/dashboard';
              }else{
                this.setState({ paymentLoading: false });
                this.btnT.removeAttribute('disabled');
              }
            },
            fail: (error, status, data) => {
              if ( status == 429 ) {
                this.setState({
                  paymentLoading: false,
                  error: {
                    visible: true,
                    type: 'warning',
                    title: 'Aviso',
                    message: 'Favor de esperar un momento'
                  }
                });
              }else{
                this.setState({
                  paymentLoading: false,
                  error: {
                    visible: true,
                    type: 'warning',
                    title: 'Aviso',
                    message: data.message
                  }
                });
              }
              this.btnT.removeAttribute('disabled');
            }
          });
        }else{
          this.setState({
            error: {
              visible: true,
              type: 'warning',
              title: 'Error',
              message: 'Tarjeta inválida, favor de verificar los datos proporcionados.'
            }
          });

          this.btnT.removeAttribute('disabled');
        }
      });
    }
  }

  getTokenCard(callback) {
    const { name, number, expiry, cvc } = this.state;
    const e = expiry.split('/');
    const exp_year = parseInt(e[1]) + 2000;
    const exp_month = e[0];

    const card = { number, name, exp_year, exp_month, cvc };

    Conekta.setPublicKey(PUBLIC);

    Conekta.Token.create({ card }, (token) => {
      callback(true, token.id);
    }, (response) => {
      console.log('response', response);
      callback(false, null);
    });
  }

  validations() {
    const { nombre, celular, correo, calle, codigo_postal, sucursalId, total, name,
      number, expiry, cvc, totalItemsCart, products, cart, tipo_tarjeta, acceptTerms } = this.state;

    let completed = true;
    let message = '';

    if ( isEmpty(nombre) ) {
      completed = false;
      message = 'Favor de proporcionar el Nombre completo.';
    }

    if ( completed && isEmpty(celular) ) {
      completed = false;
      message = 'Favor de proporcionar el Celular.';
    }

    if ( completed && celular.length !== 10 ) {
      completed = false;
      message = 'Favor de proporcionar Celular válido.';
    }

    if ( completed && ! isMobilePhone(celular) ) {
      completed = false;
      message = 'Favor de proporcionar Celular válido.';
    }

    if ( completed && isEmpty(correo) ) {
      completed = false;
      message = 'Favor de proporcionar el Correo electrónico.';
    }

    if ( completed && ! isEmail(correo) ) {
      completed = false;
      message = 'Favor de proporcionar el Correo electrónico.';
    }

    if ( completed && isEmpty(calle) ) {
      completed = false;
      message = 'Favor de proporcionar la Calle.';
    }

    if ( completed && isEmpty(codigo_postal) ) {
      completed = false;
      message = 'Favor de proporcionar el Código postal.';
    }

    if ( completed && ! isMongoId(sucursalId) ) {
      completed = false;
      message = 'Favor de proporcionar la Sucursal.';
    }

    const integrantes = [];

    products.map((p) => {
      const current = cart.find(c => c.id.toString() === p._id.toString());

      if ( current.items > 0 ) {
        for (let i = 0; i < current.items; i++) {
          const elm = `${p._id}-${i}`;

          let sexo = 0;

          if ( typeof this.frm[`nombre-${elm}`] !== 'undefined' &&
            typeof this.frm[`celular-${elm}`] !== 'undefined' &&
            typeof this.frm[`correo-${elm}`] !== 'undefined' &&
            typeof this.frm[`rdo1-${elm}`] !== 'undefined' && typeof this.frm[`rdo2-${elm}`] !== 'undefined' ) {

            if ( this.frm[`rdo1-${elm}`].checked ) {
              sexo = '1';
            }else if ( this.frm[`rdo2-${elm}`].checked ) {
              sexo = '2';
            }

            if ( ! isEmpty(this.frm[`nombre-${elm}`].value) && ! isEmpty(this.frm[`celular-${elm}`].value)
              && this.frm[`celular-${elm}`].value.length === 10 && isMobilePhone(this.frm[`celular-${elm}`].value)
              && ! isEmpty(this.frm[`correo-${elm}`].value) && isEmail(this.frm[`correo-${elm}`].value) && sexo !== 0 ) {
              integrantes.push({
                productoId: current.id,
                nombre: this.frm[`nombre-${elm}`].value,
                celular: this.frm[`celular-${elm}`].value,
                correo: this.frm[`correo-${elm}`].value,
                sexo
              });
            }
          }
        }
      }
    });

    if ( completed && integrantes.length <= 0 ) {
      completed = false;
      message = 'Favor de proporcionar todos los datos correctos de los integrantes.';
    }

    if ( completed && totalItemsCart !== integrantes.length ) {
      completed = false;
      message = 'Favor de proporcionar todos los datos correctos de los integrantes.';
    }

    if ( completed && total <= 0 ) {
      completed = false;
      message = 'Favor de proporcionar cuáles programas desea comprar.';
    }

    if ( completed && isEmpty(tipo_tarjeta) ) {
      completed = false;
      message = 'Favor de proporcionar el Tipo de tarjeta.';
    }

    if ( completed && tipo_tarjeta === CREDITO ) {
      if ( completed && isEmpty(number) ) {
        completed = false;
        message = 'Favor de proporcionar el Número de tarjeta.';
      }

      if ( completed && isEmpty(name) ) {
        completed = false;
        message = 'Favor de proporcionar el Nombre del tarjetahabiente.';
      }

      if ( completed && isEmpty(expiry) ) {
        completed = false;
        message = 'Favor de proporcionar la Fecha de expiración (MM/AA).';
      }

      if ( completed && isEmpty(cvc) ) {
        completed = false;
        message = 'Favor de proporcionar el CVC.';
      }

      if ( completed && !acceptTerms ) {
        completed = false;
        message = 'Favor de Aceptar los Términos y condiciones.';
      }
    }else{
      if ( completed ) {
        completed = false;
        message = 'Favor de proporcionar una tarjeta de Crédito.';
      }
    }

    if ( ! completed ) {
      this.setState({
        error: {
          visible: true,
          type: 'warning',
          title: 'Información incompleta',
          message
        }
      });

      this.btnT.removeAttribute('disabled');
    }

    return completed;
  }

  handleCallback({ issuer }, isValid) {
    if (isValid) {
      this.setState({ issuer });
    }
  }

  handleInputFocus({ target }) {
    this.setState({
      focused: target.name,
    });
  }

  handleInputChange({ target }) {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  }

  cleanError() {
    this.setState({ error: { visible: false, type: '', title: '', message: '' } });
  }

}

Suscripcion.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Suscripcion);
